import React, { Fragment } from 'react'
import Mediaquery from 'react-responsive'

import carFrameIco from '../../../static/assets/icon/car-frame.svg'
import theftIco from '../../../static/assets/icon/theft-icon.svg'
import windowTyreIco from '../../../static/assets/icon/window-tyre-icon.svg'
import thidPersionIco from '../../../static/assets/icon/third-person-icon.svg'
import greenCheckIco from '../../../static/assets/icon/green-check-icon.svg'
import './style.scss'
const InsuranceTable = props => (
  <Fragment>
    <Mediaquery minWidth={481}>
      <table className="table table-bordered text-center table--insurance table--desktop">
        <thead>
          <tr>
            <th>
              <h4>ประเภทประกันภัย</h4>
            </th>
            <th>
              <img alt="icon-insurance" src={thidPersionIco} />
              <br />
              ความคุ้มครอง
              <br />
              <b>บุคคลที่ 3</b>
            </th>
            <th>
              <img alt="icon-insurance" src={carFrameIco} />
              <br />
              ความคุ้มครอง
              <br />
              <b>Zero Excess (ตัวถัง)</b>
            </th>
            <th>
              <img alt="icon-insurance" src={theftIco} />
              <br />
              ความคุ้มครอง
              <br />
              <b>โจรกรรม</b>
            </th>
            <th>
              <img alt="icon-insurance" src={windowTyreIco} />
              <br />
              ความคุ้มครอง
              <br />
              <b>กระจก ยาง</b>
            </th>
            <th>
              <h4>ราคาต่อวัน*</h4>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>ประกันพื้นฐาน มีค่าเสียหายส่วนแรก (CDW)</td>
            <td>
              <img alt="icon-insurance" src={greenCheckIco} />
            </td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>รวมในค่าเช่าแล้ว</td>
          </tr>
          <tr>
            <td>ประกันแบบไม่มีค่าเสียหายส่วนแรก Zero Excess (SCDW)</td>
            <td>
              <img alt="icon-insurance" src={greenCheckIco} />
            </td>
            <td>
              <img alt="icon-insurance" src={greenCheckIco} />
            </td>
            <td>-</td>
            <td>-</td>
            <td>240-535 บาท</td>
          </tr>
          <tr>
            <td>ประกันแบบไม่มีค่าเสียหายส่วยแรก Zero Excess + ประกันการโจรกรรม (SCDW + TP)</td>
            <td>
              <img alt="icon-insurance" src={greenCheckIco} />
            </td>
            <td>
              <img alt="icon-insurance" src={greenCheckIco} />
            </td>
            <td>
              <img alt="icon-insurance" src={greenCheckIco} />
            </td>
            <td>-</td>
            <td>265-588 บาท</td>
          </tr>
          <tr>
            <td>ประกันแบบไม่มีค่าเสียหายส่วนแรกเต็มรูปแบบ + ประกันการโจรกรรม (PCDW+TP)</td>
            <td>
              <img alt="icon-insurance" src={greenCheckIco} />
            </td>
            <td>
              <img alt="icon-insurance" src={greenCheckIco} />
            </td>
            <td>
              <img alt="icon-insurance" src={greenCheckIco} />
            </td>
            <td>
              <img alt="icon-insurance" src={greenCheckIco} />
            </td>
            <td>350-650 บาท</td>
          </tr>
        </tbody>
      </table>
    </Mediaquery>
    <Mediaquery maxWidth={480}>
      <div className="card card--insurance card--mobile">
        <div className="card-body">
          <ul>
            <li>
              <div>ชื่อประกัน</div>
              <div>ราคาต่อวัน*</div>
            </li>
            <li>
              <div>CDW</div>
              <div>ฟรี</div>
            </li>
          </ul>
          <span>ความคุ้มครอง</span>
          <table className="table">
            <tbody>
              <tr>
                <td>
                  <div>
                    <img alt="icon-insurance" src={greenCheckIco} />
                  </div>
                </td>
                <td>
                  <div>
                    <img alt="icon-insurance" src={thidPersionIco} />
                  </div>
                  <div>บุคคลที่ 3</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="card-body">
          <ul>
            <li>
              <div>ชื่อประกัน</div>
              <div>ราคาต่อวัน*</div>
            </li>
            <li>
              <div>SCDW</div>
              <div>240-535 บาท</div>
            </li>
          </ul>
          <span>ความคุ้มครอง</span>
          <table className="table">
            <tbody>
              <tr>
                <td>
                  <div>
                    <img alt="icon-insurance" src={greenCheckIco} />
                  </div>
                </td>
                <td>
                  <div>
                    <img alt="icon-insurance" src={thidPersionIco} />
                  </div>
                  <div>บุคคลที่ 3</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <img alt="icon-insurance" src={greenCheckIco} />
                  </div>
                </td>
                <td>
                  <div>
                    <img alt="icon-insurance" src={carFrameIco} />
                  </div>
                  <div>Zero Excess (ตัวถัง)</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="card-body">
          <ul>
            <li>
              <div>ชื่อประกัน</div>
              <div>ราคาต่อวัน*</div>
            </li>
            <li>
              <div>SCDW+TP</div>
              <div>265-588 บาท</div>
            </li>
          </ul>
          <span>ความคุ้มครอง</span>
          <table className="table">
            <tbody>
              <tr>
                <td>
                  <div>
                    <img alt="icon-insurance" src={greenCheckIco} />
                  </div>
                </td>
                <td>
                  <div>
                    <img alt="icon-insurance" src={thidPersionIco} />
                  </div>
                  <div>บุคคลที่ 3</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <img alt="icon-insurance" src={greenCheckIco} />
                  </div>
                </td>
                <td>
                  <div>
                    <img alt="icon-insurance" src={carFrameIco} />
                  </div>
                  <div>Zero Excess (ตัวถัง)</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <img alt="icon-insurance" src={greenCheckIco} />
                  </div>
                </td>
                <td>
                  <div>
                    <img alt="icon-insurance" src={theftIco} />
                  </div>
                  <div>โจรกรรม</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="card-body">
          <ul>
            <li>
              <div>ชื่อประกัน</div>
              <div>ราคาต่อวัน*</div>
            </li>
            <li>
              <div>PCDW+TP</div>
              <div>350-650 บาท</div>
            </li>
          </ul>
          <span>ความคุ้มครอง</span>
          <table className="table">
            <tbody>
              <tr>
                <td>
                  <div>
                    <img alt="icon-insurance" src={greenCheckIco} />
                  </div>
                </td>
                <td>
                  <div>
                    <img alt="icon-insurance" src={thidPersionIco} />
                  </div>
                  <div>บุคคลที่ 3</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <img alt="icon-insurance" src={greenCheckIco} />
                  </div>
                </td>
                <td>
                  <div>
                    <img alt="icon-insurance" src={carFrameIco} />
                  </div>
                  <div>Zero Excess (ตัวถัง)</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <img alt="icon-insurance" src={greenCheckIco} />
                  </div>
                </td>
                <td>
                  <div>
                    <img alt="icon-insurance" src={theftIco} />
                  </div>
                  <div>โจรกรรม</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <img alt="icon-insurance" src={greenCheckIco} />
                  </div>
                </td>
                <td>
                  <div>
                    <img alt="icon-insurance" src={windowTyreIco} />
                  </div>
                  <div>กระจก ยาง</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Mediaquery>
  </Fragment>
)
export default InsuranceTable
