import React from 'react'

const question = {
  icon: '',
  question: 'มีบริการรถเช่าพร้อมคนขับมั้ย?',
  answer: props => (
    <div style={{ padding: 15 }}>
      <div className="indent">
        โดยส่วนใหญ่จะมีบริการรถพร้อมคนขับในประเภทรถตู้
        ท่านสามารถค้นหารถตู้พร้อมคนขับในจังหวัดที่มีบริการได้จากเว็บไซต์หรือสอบถามจากทางเจ้าหน้าที่
        Drivehub ที่เบอร์ 02-038-5222 หรือ Line Id: @Drivehub
      </div>
      <div className="indent">
        สำหรับรถยนต์อื่นๆพร้อมคนขับ จะมีให้บริการเพียง 2 จังหวัดเท่านั้น คือ เชียงใหม่ และนครพนม
        หากต้องการที่จะเช่ารถเล็กพร้อมคนขับ กรุณาติดต่อเจ้าหน้าที่ Drivehub ตามรายละเอียดด้านบน
      </div>
    </div>
  ),
}

export default question
