import React from 'react'
import styled from 'styled-components'
import Tab, { TabPane } from '../components/tab'
import TabTitle from '../components/tab-title'
import './style.scss'

export default prefix => {
  const content = {
    th: {
      header: ['การยกเลิกการจอง', 'การเปลี่ยนแปลงการจอง'],
      content: {
        bigbrand: {
          description: [
            [
              'ท่านสามารถแจ้งการยกเลิกโดยตอบกลับอีเมลล์การจองที่ท่านได้รับจาก Drivehub โดยการยกเลิก 48 ชม.ก่อนรับรถ ระบบจะส่งเรื่องขอคืนเงินไปยังธนาคารภายในวันทำการถัดไป',
              '*การคืนเงินจะขึ้นอยู่กับนโยบายของแต่ละธนาคาร ตามปกติแล้วผู้ถือบัตรจะได้รับเงินคืนในรอบบิลถัดไป',
              'เงื่อนไขในการได้รับเงินคืนในกรณีที่มีการยกเลิกการจองนั้น ให้เป็นไปตามที่ระบุดังต่อไปนี้',
              'หากมีการยกเลิก 48 ชั่วโมง ก่อนกำหนดรับรถ ท่านจะได้รับเงินคืนเต็มจำนวน',
              'หากมีการยกเลิกภายใน 48 ชั่วโมง ก่อนกำหนดรับรถ ท่านอาจจะได้รับเงินคืนบางส่วน เป็นจำนวนที่ได้ชำระเข้ามาหักลบด้วยค่าเช่า 3 วัน',
              'ถ้าท่านได้ทำจองรถน้อยกว่า 3 วัน จะไม่มีค่าใช้จ่ายใดๆเพิ่มเติม แต่จะไม่ได้รับเงินคืน',
              'ในกรณีที่ท่านไม่สามารถไปรับรถได้ในเวลาที่ตกลงกัน / หรือเอกสารที่จำเป็นไม่ครบ / หรือบัตรเครดิตมีวงเงินไม่เพียงพอที่จะเช่ารถ บริษัทรถเช่าจะสงวนสิทธิ์การเช่า และไม่ได้รับเงินคืน ทั้งนี้',
            ],
            [
              'ท่านสามารถทำการเปลี่ยนแปลงการจองได้ เพียงแจ้งข้อมูลที่ต้องการเปลี่ยนแปลงกับทางเจ้าหน้าที่ Drivehub ผ่าน 2 ช่องทาง',
              '1.',
              '02-038-5222',
              '2. Official Line@ :',
              '@drivehub',
              'ตลอดช่วงเวลา 08:00 - 22:00 น.',
              '*หลังจากที่ท่านแจ้งข้อมูลเปลี่ยนแปลงแล้ว โปรดรอการติดต่อกลับเพื่อยืนยันการเปลี่ยนแปลง ',
            ],
          ],
          subContent: [
            {
              header: 'กรณีที่ไม่แสดงตัว',
              text: [
                'ท่านไม่สามารถได้รับเงินคืนในกรณีที่ท่านไม่แสดงตัว ซึ่งหมายถึงเมื่อ',
                'ท่านต้องการยกเลิก แต่ไม่ได้แจ้งให้ทางบริษัทหรือ Drivehub.co ทราบก่อนจะเริ่มกำหนดการเช่าตามเวลาที่กำหนด',
                'ไม่สามารถรับรถได้ตามเวลาที่แจ้งไว้',
                'ไม่สามารถให้เอกสารบริษัทรถเช่าร้องขอให้แสดงเมื่อไปรับรถ',
                'ไม่สามารถที่จะให้บัตรเครดิตในชื่อของผู้ขับขี่หลักหรือวงเงินในบัตรเครดิตไม่เพียงพอค่ามัดจำ',
                '*ทั้งนี้บริษัทรถเช่าขอสงวนสิทธิที่จะไม่ส่งมอบรถให้ลูกค้าที่มารับรถไม่ตรงเวลา หรือไม่มีเอกสารในการจองรถเช่า หรือวงเงินในบัตรเครดิตไม่เพียงพอสำหรับเป็นค่ามัดจำการเช่า',
              ],
            },
          ],
        },
        original: {
          description: [
            [
              'กรณียกเลิก 72 ชั่วโมงก่อนรับรถ ท่านจะได้รับค่าล็อคคิวรถคืน ทั้งนี้เงื่อนไขการคืนเงินบริษัทรถเช่าอาจมีการเปลี่ยนแปลง ท่านสามารถสอบถามข้อมูลกับบริษัทรถเช่าได้โดยตรง',
              'ท่านสามารถแจ้งการยกเลิกการจองได้ 2 ช่องทาง',
              '1. บริษัทรถเช่าที่ท่านทำการจองเข้ามา',
              '2. เจ้าหน้าที่ Drivehub ที่เบอร์',
              '02-038-5222',
              'หรือ Line Id: ',
              '@Drivehub',
            ],
            [
              'ท่านสามารถแจ้งการเปลี่ยนแปลงได้ทั้ง 2 ช่องทาง',
              '1. บริษัทรถเช่าที่ท่านทำการจองเข้ามา',
              '2. เจ้าหน้าที่ Drivehub ที่เบอร์',
              '02-038-5222',
              'หรือ Line Id: ',
              '@Drivehub',
              'ค่าใช้จ่าย และรถที่ท่านเลือก อาจจะเปลี่ยนแปลงได้ทั้งนี้เจ้าหน้าที่จะตรวจสอบข้อมูลให้ และแจ้งกลับ',
            ],
          ],
        },
      },
    },
    en: {
      header: ['Cancellation:', 'Modification:'],
      content: {
        bigbrand: {
          description: [
            [
              'ท่านสามารถแจ้งการยกเลิกโดยตอบกลับอีเมลล์การจองที่ท่านได้รับจาก Drivehub โดยการยกเลิก 48 ชม.ก่อนรับรถ ระบบจะส่งเรื่องขอคืนเงินไปยังธนาคารภายในวันทำการถัดไป',
              '*การคืนเงินจะขึ้นอยู่กับนโยบายของแต่ละธนาคาร ตามปกติแล้วผู้ถือบัตรจะได้รับเงินคืนในรอบบิลถัดไป',
              'เงื่อนไขในการได้รับเงินคืนในกรณีที่มีการยกเลิกการจองนั้น ให้เป็นไปตามที่ระบุดังต่อไปนี้',
              'หากมีการยกเลิก 48 ชั่วโมง ก่อนกำหนดรับรถ ท่านจะได้รับเงินคืนเต็มจำนวน',
              'หากมีการยกเลิกภายใน 48 ชั่วโมง ก่อนกำหนดรับรถ ท่านอาจจะได้รับเงินคืนบางส่วน เป็นจำนวนที่ได้ชำระเข้ามาหักลบด้วยค่าเช่า 3 วัน',
              'ถ้าท่านได้ทำจองรถน้อยกว่า 3 วัน จะไม่มีค่าใช้จ่ายใดๆเพิ่มเติม แต่จะไม่ได้รับเงินคืน',
              'ในกรณีที่ท่านไม่สามารถไปรับรถได้ในเวลาที่ตกลงกัน / หรือเอกสารที่จำเป็นไม่ครบ / หรือบัตรเครดิตมีวงเงินไม่เพียงพอที่จะเช่ารถ บริษัทรถเช่าจะสงวนสิทธิ์การเช่า และไม่ได้รับเงินคืน ทั้งนี้',
            ],
            [
              'ท่านสามารถทำการเปลี่ยนแปลงการจองได้ เพียงแจ้งข้อมูลที่ต้องการเปลี่ยนแปลงกับทางเจ้าหน้าที่ Drivehub ผ่าน 2 ช่องทาง',
              '1.',
              '02-038-5222',
              '2. Official Line@ :',
              '@drivehub',
              'ตลอดช่วงเวลา 08:00 - 22:00 น.',
              '*หลังจากที่ท่านแจ้งข้อมูลเปลี่ยนแปลงแล้ว โปรดรอการติดต่อกลับเพื่อยืนยันการเปลี่ยนแปลง ',
            ],
          ],
          subContent: [
            {
              header: 'กรณีที่ไม่แสดงตัว',
              text: [
                'ท่านไม่สามารถได้รับเงินคืนในกรณีที่ท่านไม่แสดงตัว ซึ่งหมายถึงเมื่อ',
                'ท่านต้องการยกเลิก แต่ไม่ได้แจ้งให้ทางบริษัทหรือ Drivehub.co ทราบก่อนจะเริ่มกำหนดการเช่าตามเวลาที่กำหนด',
                'ไม่สามารถรับรถได้ตามเวลาที่แจ้งไว้',
                'ไม่สามารถให้เอกสารบริษัทรถเช่าร้องขอให้แสดงเมื่อไปรับรถ',
                'ไม่สามารถที่จะให้บัตรเครดิตในชื่อของผู้ขับขี่หลักหรือวงเงินในบัตรเครดิตไม่เพียงพอค่ามัดจำ',
                '*ทั้งนี้บริษัทรถเช่าขอสงวนสิทธิที่จะไม่ส่งมอบรถให้ลูกค้าที่มารับรถไม่ตรงเวลา หรือไม่มีเอกสารในการจองรถเช่า หรือวงเงินในบัตรเครดิตไม่เพียงพอสำหรับเป็นค่ามัดจำการเช่า',
              ],
            },
          ],
        },
        original: {
          description: [
            [
              'กรณียกเลิก 72 ชั่วโมงก่อนรับรถ ท่านจะได้รับค่าล็อคคิวรถคืน ทั้งนี้เงื่อนไขการคืนเงินบริษัทรถเช่าอาจมีการเปลี่ยนแปลง ท่านสามารถสอบถามข้อมูลกับบริษัทรถเช่าได้โดยตรง',
              'ท่านสามารถแจ้งการยกเลิกการจองได้ 2 ช่องทาง',
              '1. บริษัทรถเช่าที่ท่านทำการจองเข้ามา',
              '2. เจ้าหน้าที่ Drivehub ที่เบอร์',
              '02-038-5222',
              'หรือ Line Id: ',
              '@Drivehub',
            ],
            [
              'ท่านสามารถแจ้งการเปลี่ยนแปลงได้ทั้ง 2 ช่องทาง',
              '1. บริษัทรถเช่าที่ท่านทำการจองเข้ามา',
              '2. เจ้าหน้าที่ Drivehub ที่เบอร์',
              '02-038-5222',
              'หรือ Line Id: ',
              '@Drivehub',
              'ค่าใช้จ่าย และรถที่ท่านเลือก อาจจะเปลี่ยนแปลงได้ทั้งนี้เจ้าหน้าที่จะตรวจสอบข้อมูลให้ และแจ้งกลับ',
            ],
          ],
        },
      },
    },
  }
  const text = content[prefix]
  const textOriginal = text.content.original.description
  const textBigbrand = text.content.bigbrand.description
  const bigbrandSubContent = text.content.bigbrand.subContent

  return (
    <div className="content-page">
      <Tab>
        <TabPane hash="cash" title={<TabTitle type="local" />}>
          <div className="col-12">
            <ContentContainer>
              <div style={{ paddingBottom: '35px' }}>
                <h2>{text.header[0]}</h2>
                <p>{textOriginal[0][0]}</p>
                <p>{textOriginal[0][1]}</p>
                <p>{textOriginal[0][2]}</p>
                <div>
                  {textOriginal[0][3]}{' '}
                  <span>
                    <a rel="noreferrer" href="tel:+6620385222">
                      {textOriginal[0][4]}{' '}
                    </a>
                  </span>
                  {textOriginal[0][5]}{' '}
                  <span>
                    <a rel="nofollow" href="https://line.me/ti/p/%40drivehub">
                      {textOriginal[0][6]}
                    </a>
                  </span>
                </div>
              </div>
              <div>
                <div>
                  <h2>{text.header[1]}</h2>
                  <p>{textOriginal[1][0]}</p>
                  <p>{textOriginal[1][1]}</p>
                  <div>
                    {textOriginal[1][2]}{' '}
                    <span>
                      <a rel="noreferrer" href="tel:+6620385222">
                        {textOriginal[1][3]}{' '}
                      </a>
                    </span>
                    {textOriginal[1][4]}{' '}
                    <span>
                      <a rel="nofollow" href="https://line.me/ti/p/%40drivehub">
                        {textOriginal[1][5]}
                      </a>
                    </span>
                  </div>
                  <p>{textOriginal[1][6]}</p>
                </div>
              </div>
            </ContentContainer>
          </div>
        </TabPane>
        <TabPane hash="credit" title={<TabTitle type="inter" />}>
          <div className="col-12">
            <ContentContainer>
              <div style={{ paddingBottom: '35px' }}>
                <h2>{text.header[0]}</h2>
                <p>{textBigbrand[0][0]}</p>
                <p>
                  <strong>{textBigbrand[0][1]}</strong>
                </p>
                <p className="pt-2">{textBigbrand[0][2]}</p>
                <ul>
                  {textBigbrand[0]
                    .filter((_, index) => index > 2)
                    .map(el => (
                      <li>{el}</li>
                    ))}
                </ul>
                <div className="pt-2">
                  <p className="font-weight-bold">
                    <u>{bigbrandSubContent[0].header}</u>
                  </p>
                  {bigbrandSubContent[0].text[0]}
                  <ul>
                    {bigbrandSubContent[0].text
                      .filter(
                        (_, i) =>
                          i > 0 && i < bigbrandSubContent[0].text.length - 1,
                      )
                      .map(el => {
                        return <li>{el}</li>
                      })}
                  </ul>
                  <b>
                    {
                      bigbrandSubContent[0].text[
                        bigbrandSubContent[0].text.length - 1
                      ]
                    }
                  </b>
                </div>
              </div>
              <div>
                <h2>{text.header[1]}</h2>
                <p>{textBigbrand[1][0]}</p>
                <p>
                  {textBigbrand[1][1]}{' '}
                  <span>
                    <a rel="noreferrer" href="tel:+6620385222">
                      {textBigbrand[1][2]}
                    </a>
                  </span>
                </p>
                <p>
                  {textBigbrand[1][3]}{' '}
                  <span>
                    <a ref="nofollow" href="https://line.me/ti/p/%40drivehub">
                      {textBigbrand[1][4]}
                    </a>
                  </span>
                </p>
              </div>
              <p>{textBigbrand[1][5]}</p>
              <p>
                <strong>{textBigbrand[1][6]}</strong>
              </p>
            </ContentContainer>
          </div>
        </TabPane>
      </Tab>
    </div>
  )
}

const ContentContainer = styled.div`
  p {
    margin: 0;
  }
  li {
    margin: 0;
  }
  ul {
    margin-bottom: 8px;
  }
`
