import React from 'react'
import PropTypes from 'prop-types'
import { BadgeContainer } from '../../components/badge'
import LogoBox from '../../components/logo-box'
import local from '../../../static/assets/badges/local.svg'
import inter from '../../../static/assets/badges/inter.svg'
import { INTER_LOGOS, LOCAL_LOGOS } from './const.js'
import './style.scss'

const RentalType = (prefix = 'th') => {
  const content = {
    th: [
      'Drivehub มีบริษัทรถเช่าให้บริการคุณถึง 2 แบบ เพื่ออำนวยความสะดวกให้ทั้งลูกค้าที่มีและไม่มีบัตรเครดิต ทั้งนี้บริษัทรถเช่าแต่ละประเภทจะมีข้อแตกต่างกันดังนี้',
      'บริษัทรถเช่ารายใหญ่',
      'มาตรฐานสากล ชำระค่าเช่าบนหน้าเว็บ พร้อมยืนยันการจองทันที ไม่ต้องรอการติดต่อและยืนยันกับบริษัท',
      'มองหาสัญลักษณ์',
      'ต้องใช้บัตรเครดิตเพื่อยืนยันการรับรถเท่านั้น!',
      'หากไม่นำไปบริษัทรถเช่าจะไม่สามารถปล่อยรถให้คุณได้',
      'บริษัทรถเช่าท้องถิ่น',
      <>
        บริการเป็นกันเอง พร้อมรถเช่าหลากหลายรุ่นให้เลือก
        <b>สามารถใช้เงินสดในการเช่าได้</b>
      </>,
      'บริษัทรถเช่าทั้งสองประเภทแตกต่างกันอย่างไร?',
    ],
    en: [
      'At Drivehub, we utilize 2 distinct types of rental companies to better serve customers both with and without a credit card. Please read the following conditions carefully as these 2 types operate differently in terms of payment, confrimation, vehicle pick-up and drop off, and customers guarantees.',
      'International Rental Companies',
      'International standard quality and assurance. When renting with this type of companies, payment is made directly on our website with instant confirmation.',
      'Look for symbol:',
      'Payment credit card must be presented at pick-up!',
      'Rental companies will not release the car if credit card is not presented.',
      'Local Rental Companies',
      <>
        Meets the quality of Thai hospitality, with a wide range of vehicles to
        choose from.
        <b> No credit card needed. Cash payment is accepted.</b>
      </>,
      'What is the differences? International vs. local rental companies?',
    ],
  }

  const tableContent = {
    th: [
      {
        icon: 'flaticon-credit-card',
        topic: (
          <>
            <b>ใช้บัตรเครดิต</b>
            <span className="topic-description">เพื่อชำระเงินและรับรถ</span>
          </>
        ),
        inter: <i className="flaticon-check" />,
        local: <i className="flaticon-check" />,
        localText: 'มีค่าธรรมเนียมเพิ่มเติม',
      },
      {
        icon: 'flaticon-baht',
        topic: (
          <>
            <b>ใช้เงินสด/โอนเงิน</b>
            <span className="topic-description">เพื่อชำระเงินและรับรถ</span>
          </>
        ),
        inter: '-',
        local: <i className="flaticon-check" />,
        localText: '',
      },
      {
        icon: 'flaticon-file',
        topic: (
          <>
            <b>ไม่ต้องส่งเอกสารก่อนเช่า</b>
            <span className="topic-description">
              เช่น ตั๋วเครื่องบิน, สลิปเงินเดือน (กรณีเช่าใน กทม.)
            </span>
            <div>
              <a
                href={`/${prefix}/documents/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                อ่านเพิ่มเติมเกี่ยวกับเอกสาร
              </a>
            </div>
          </>
        ),
        inter: <i className="flaticon-check" />,
        local: '-',
        localText: '',
      },
      {
        icon: 'flaticon-dark-ray',
        topic: (
          <>
            <b>ยืนยันการจองทันที</b>
          </>
        ),
        inter: <i className="flaticon-check" />,
        local: '',
        localText: 'ยืนยันภายใน 15 นาที',
      },
      {
        icon: 'flaticon-car',
        topic: (
          <>
            <b>การันตีได้รถคันที่เลือก</b>
          </>
        ),
        inter: '-',
        local: <i className="flaticon-check" />,
        localText: '',
      },
      {
        icon: 'flaticon-location',
        topic: (
          <>
            <b>บริการรับ-ส่งนอกสถานที่</b>
          </>
        ),
        inter: '-',
        local: <i className="flaticon-check" />,
        localText: '',
      },
      {
        icon: 'flaticon-flaticon1558008084-svg',
        topic: (
          <>
            <b>การชำระเงิน ณ วันรับรถ</b>
          </>
        ),
        inter: '-',
        local: <i className="flaticon-check" />,
        localText: '',
      },
    ],
    en: [
      {
        icon: 'flaticon-credit-card',
        topic: (
          <>
            <b>Credit card </b>
            <span className="topic-description">
              (for payment and vehicle pick-up)
            </span>
          </>
        ),
        inter: <i className="flaticon-check" />,
        local: <i className="flaticon-check" />,
        localText: 'surcharge applies',
      },
      {
        icon: 'flaticon-baht',
        topic: (
          <>
            <b>Cash/bank transfer </b>
            <span className="topic-description">
              (for payment and vehicle pick-up)
            </span>
          </>
        ),
        inter: '-',
        local: <i className="flaticon-check" />,
        localText: '',
      },
      {
        icon: 'flaticon-file',
        topic: (
          <>
            <b>No addtional documents required </b>
            <span className="topic-description">
              such as flight itinerary, pay slip (for Bangkok region)
            </span>
            <div>
              <a
                href={`/${prefix}/documents/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Required rentals documents. Read more
              </a>
            </div>
          </>
        ),
        inter: <i className="flaticon-check" />,
        local: '-',
        localText: '',
      },
      {
        icon: 'flaticon-dark-ray',
        topic: (
          <>
            <b>Instant confirmation</b>
          </>
        ),
        inter: <i className="flaticon-check" />,
        local: '',
        localText: 'within 15 minutes',
      },
      {
        icon: 'flaticon-car',
        topic: (
          <>
            <b>Get the exact car you choose</b>
          </>
        ),
        inter: '-',
        local: <i className="flaticon-check" />,
        localText: '',
      },
      {
        icon: 'flaticon-location',
        topic: (
          <>
            <b>Vehicle delivery service </b>
            <span className="topic-description">(outside service area)</span>
          </>
        ),
        inter: '-',
        local: <i className="flaticon-check" />,
        localText: '',
      },
      {
        icon: 'flaticon-flaticon1558008084-svg',
        topic: (
          <>
            <b>Pay at pick-up</b>
          </>
        ),
        inter: '-',
        local: <i className="flaticon-check" />,
        localText: '',
      },
    ],
  }

  const text = content[prefix]
  const table = tableContent[prefix]
  return (
    <div>
      <p>{text[0]}</p>
      <div className="type-info__container">
        <div className="bigbrand-type__container">
          <div>
            <div className="header">
              <h2>{text[1]}</h2>
              <div className="type-symbol">
                <small className="d-block">{text[3]}</small>
                <div className="d-inline-block">
                  <BadgeContainer
                    icon={inter}
                    text="INTER"
                    background="#0053BA"
                    fontColor="#fff"
                  />
                </div>
              </div>
            </div>
            <p>{text[2]}</p>
            <div className="logo-box__wrapper">
              <LogoBox logos={INTER_LOGOS} />
            </div>
            <div className="warning-box">
              <div>
                <i className="flaticon-warning" />
              </div>
              <div>
                <h3>{text[4]}</h3>
                <p>{text[5]}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="local-type__container">
          <div>
            <div className="header">
              <h2>{text[6]}</h2>
              <div className="type-symbol">
                <small className="d-block">{text[3]}</small>
                <div className="d-inline-block">
                  <BadgeContainer
                    icon={local}
                    text="LOCAL"
                    background="#FF8D41"
                    fontColor="#fff"
                  />
                </div>
              </div>
            </div>
            <p>{text[7]}</p>
            <div className="logo-box__wrapper">
              <LogoBox logos={LOCAL_LOGOS} />
            </div>
          </div>
        </div>
      </div>
      <div className="compare-table__container">
        <h2>{text[8]}</h2>
        <CompareTable table={table} />
      </div>
    </div>
  )
}

const CompareTable = ({ table }) => (
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col" />
        <th scope="col" />
        <th scope="col">
          <div className="d-inline-block">
            <BadgeContainer
              icon={inter}
              text="INTER"
              background="#0053BA"
              fontColor="#fff"
              style={{ padding: '0 6px' }}
            />
          </div>
        </th>
        <th scope="col">
          <div className="d-inline-block">
            <BadgeContainer
              icon={local}
              text="LOCAL"
              background="#FF8D41"
              fontColor="#fff"
              style={{ padding: '0 6px' }}
            />
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      {table.map(row => (
        <tr>
          <th className="table-icon" scope="row">
            <i className={row.icon} />
          </th>
          <td>{row.topic}</td>
          <td>{row.inter}</td>
          <td>
            {row.local}
            {row.localText && (
              <div>
                <small>{row.localText}</small>
              </div>
            )}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

RentalType.propTypes = {
  prefix: PropTypes.string,
}

export default RentalType
