import React, { Component } from 'react'
import questions from './questions'
import Panels from '../panels'

export default class FAQ extends Component {
  state = {}
  get panels() {
    return questions.map(q => ({
      title: q.question,
      content: q.answer({
        toggle: index =>
          this.component.setState({
            open: [...this.component.state.open, index],
          }),
        ...this.props,
      }),
      show_google_faq: false,
    }))
  }

  render() {
    return (
      <div>
        <Panels
          panels={this.panels}
          noHeader
          isContentPage={this.props.isContentPage}
          ref={ref => (this.component = ref)}
        />
      </div>
    )
  }
}
