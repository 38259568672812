import React from 'react'

const question = {
  icon: '',
  question: 'รับรถไปแล้วต้องการคืนก่อนกำหนด จะได้เงินคืนหรือไม่?',
  answer: props => (
    <div style={{ padding: 15 }}>
      <b>
        <u>หากท่านทำการจองรถที่ไม่ต้องใช้บัตรเครดิต</u>
      </b>
      <p className="indent">
        ท่านสามารถแจ้งความประสงค์ที่จะคืนรถก่อนกำหนดได้ตลอดระยะเวลาการเช่า
        โดยท่านจะได้รับเงินคืนจำนวน 50% ของค่าเช่าสำหรับวันที่ไม่ได้ใช้งาน*
        ซึ่งวันที่ไม่ได้ใช้เริ่มนับจาก 24
        ชั่วโมงหลังจากที่ท่านแจ้งขอคืนรถก่อนกำหนด เช่น เช่ารถวันที่ 22 พ.ย. เวลา
        10.00 ถึงวันที่ 27 พ.ย. เวลา 10.00 รวมการเช่าคือ 5 วันเต็ม ค่าเช่าวันละ
        900 บาท หลังจากทำการเช่ารถไป 2 วัน และต้องการคืนรถก่อนกำหนด
        จึงโทรเข้ามาแจ้งเจ้าหน้าที่เพื่อขอคืนรถ
        จำนวนวันที่ไม่ได้ใช้งานที่ท่านจะได้เงินคืนคือ 2 วัน และได้รับคืนวันละ
        450 บาท (50% ของค่าเช่ารายวัน) รวมที่จะได้รับคืนทั้งสิ้น 900 บาท เป็นต้น
        ทั้งนี้หากจำนวนวันการเช่าเหลือเพียงวันเดียวนับจากวันที่ท่านแจ้ง
        ทางบริษัทขอสงวนสิทธิ์ในการคืนเงิน
      </p>
      <b>
        <u>หากท่านทำการจองรถที่ใช้บัตรเครดิต</u>
      </b>
      <div className="indent">ท่านจะไม่ได้รับการคืนเงิน</div>
    </div>
  ),
}

export default question
