import React from 'react'
import styled from 'styled-components'
import Faq from '../components/faq'
import CityList from '../components/city-list'
import { paragraphRenderer } from './utils'
import './style.scss'

export default prefix => {
  const json = {
    th: [
      'เกี่ยวกับ Drivehub',
      'Drivehub คือ เว็บไซต์ผู้นำด้านการจองรถเช่า ที่อำนวยความสะดวกทั้งด้านการจองรถ คุณภาพรถ การเลือกรถอย่างอิสระ  และการค้นหารถเช่าราคาที่ดีที่สุด',
      'Drivehub ได้รวบรวมบริษัทรถเช่านานาชาติ(ตามเคาน์เตอร์สนามบิน) และบริษัทรถเช่าท้องถิ่นจากทั่วประเทศ มากกว่า 500 บริษัท ไว้ภายในเว็บไซต์' +
        'ซึ่งรถเช่าภายในเว็บไซต์มีมากกว่า 5000 คัน ให้ลูกค้าสามารถเปรียบเทียบและค้นหารถเช่าราคาที่ดีที่สุด ผ่านเว็บไซต์ไดรฟ์ฮับ โดยเราไม่มีนโยบายเก็บค่าธรรมเนียมการจอง หรือค่าการจัดการใดๆทั้งสิ้นจากผู้เช่า' +
        'ทั้งนี้เรายังได้ทำสัญญานโยบายคุณภาพและความปลอดภัยของรถยนต์กับผู้ประกอบการรถเช่าที่เป็นพันธมิตรกับเรา',
      'รถยนต์ทุกคันที่อยู่ในระบบของเราจำเป็นต้องผ่านการตรวจสภาพรถยนต์มาตรฐานสากลทุกๆ 10,000 กิโลเมตร เพื่อให้ผู้เช่ามั่นใจว่าในการเดินทางแต่ละครั้งของคุณจะเป็นการเดินทางที่ปลอดภัย' +
        'เป้าหมายหลักของไดรฟ์ฮับคือเป็นช่องทางการเช่ารถที่สะดวก รวดเร็ว คุ้มค่า ปลอดภัยและง่ายที่สุดสำหรับผู้เช่ารถ Drivehub นอกจากการจองผ่านช่องทาง www.drivehub.com เรามีแผนกบริการลูกค้าสัมพันธ์สามารถติดต่อคุยกับเจ้าหน้าที่ ได้ตลอดเวลาทำการ 08.00 - 22.00น. ทุกวัน',
      'เว็บไซต์ Drivehub มีลูกค้าที่มั่นใจใช้บริการมากถึง 100,000 การเช่าในปี 2018 ที่ผ่านมา',
      'จังหวัดที่ให้บริการ',
      'ด้วยบริการรถเช่ามากกว่า 500 บริษัทเช่ารถจากกว่า 30 จังหวัดทั่วประเทศ',
      'คำถามที่มักพบบ่อย',
    ],
    en: [
      'About Us:',
      'We are a leading car rental online platform providing convenience and guarenteed best priced in every rental. We provide customers with the freedom and convenience to browse and book vehicles from wide range of choices. We ensure all vehicles listed on our website are maintained and performs at the highest quality.',
      'We combined over 500 international car rental companies (at airport counters) and local car rental companies throughout the country into one website ' +
        'putting over 5,000 available cars at the tip of you fingers, allowing the customer to compare and browse for rental cars at the best price. ' +
        'We do not have any policies that charge booking fees or management fees from lessees' +
        'All cars on our website are required to pass car inspection according to international standards every 10,000 kilometers to ensure that each customer travels in safety. Drivehub‟s main goal is to be a channel that offers convenient, quick, valuable, safe, and easiest method to rent cars for our customers. In addition to booking through www.drivehub.com, we also have a Customer Service Department where staff can be contacted during daily business hours from 08:00-22:00 hrs.',
      'In 2018, Drivehub gained customers confidence with over 100,000 completed bookings.',
      '',
      'Available Provinces:',
      'Over 500 rental operaters in 30 provinces nationwide:',
    ],
  }
  const text = json[prefix]

  return (
    <Container className="content-page">
      <div className="mb-3">
        <h2>{text[0]}</h2>
        {paragraphRenderer([text[1], text[2], text[3], text[4]])}
      </div>
      <div className="pt-4 mb-3" id="our-services">
        <h2>{text[5]}</h2>
        {paragraphRenderer([text[6]])}
        <CityList prefix={prefix} />
      </div>
      <h2>{text[7]}</h2>
      <Faq prefix={prefix} isContentPage />
    </Container>
  )
}

const Container = styled.div`
  .city-list {
    div {
      flex: 0 1 25%;
      @media (max-width: 768px) {
        flex: 0 1 50%;
      }
    }
    a {
      color: #0078ff;
      text-decoration: none;
    }
  }
  .panels__container {
    background: #fff;
  }
  .hide {
    display: none;
  }
  .panels__box {
    padding: 0px;
  }
  .panels__container {
    padding: 0px;
  }
`
