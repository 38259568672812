import React from 'react'
import styled from 'styled-components'
import json from './car-standard.json'
import './style.scss'

export default prefix => {
  const data = json[prefix]
  return (
    <Container className="content-page">
      <h2>{data.title}</h2>

      <div className="car--list">
        {data.list.map(item => {
          return (
            <div className="car" key={item.title}>
              <div>
                <img src={item.src} alt={item.title} />
                <div className="title">{item.title}</div>
              </div>

              <p>{item.description}</p>
            </div>
          )
        })}
      </div>
    </Container>
  )
}

const Container = styled.div`
  .car--list {
    display: flex;
    flex-direction: column;

    .car {
      min-height: 112px;
      border: 1px solid #d9d9d9;
      margin-bottom: 10px;
      padding: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title {
        font-size: 14px;
        font-weight: bold;
        color: #4a4a4a;
        text-align: center;
      }
      img {
        max-height: 95px;
        max-width: 90px;
      }
      p {
        margin-bottom: 0px;
      }
      & > div {
        margin-right: 15px;
        min-height: 90px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      @media only screen and (min-width: 769px) {
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        p {
          height: 100%;
        }
      }
    }
  }
`
