import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import ContentList from '../../components/content-list'
import './style.scss'

class Content extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isMobile: true,
    }
  }

  componentDidMount() {
    const initialWidth = window.innerWidth
    this.setState({ isMobile: initialWidth < 768 ? true : false })

    let widthChecker = window.matchMedia('(max-width: 768px)')
    widthChecker.addListener(e => {
      if (e.matches) {
        this.setState({ isMobile: true })
      } else {
        this.setState({ isMobile: false })
      }
    })
  }

  render() {
    const data = this.props.data
    const { isMobile } = this.state
    const { markdownRemark } = data // data.markdownRemark holds our post data
    const { frontmatter } = markdownRemark
    const prefix = this.props.pathContext.prefix
    let ContentComponent
    try {
      ContentComponent = require(`../../contents/${frontmatter.path}`).default
    } catch (error) {
      ContentComponent = () => {}
    }
    return (
      <div className="content--container">
        <Layout isMobile={isMobile} prefix={prefix} theme="black">
          <SEO
            title={frontmatter[`name_${prefix}`]}
            canonical={frontmatter[`rel_canonical_${prefix}`]}
            prefix={prefix}
            path={frontmatter.path}
            description={frontmatter[`description_${prefix}`]}
            keywords={frontmatter[`keywords_${prefix}`]}
          />
          <div className="container content-box">
            <div className="row">
              <div className="col-md-3 col-12 p-0">
                <ContentList
                  currentPathname={this.props.location.pathname}
                  isMobile={isMobile}
                  prefix={prefix}
                  listView
                />
              </div>
              <div className="col-md-9 col-12 content-body">
                {!isMobile && (
                  <h1 className="content-body__header">
                    {frontmatter[`name_${prefix}`]}
                  </h1>
                )}
                {ContentComponent(prefix, isMobile)
                // || (
                //     <div
                //       dangerouslySetInnerHTML={{
                //         __html: frontmatter[`body_${prefix}`],
                //       }}
                //     />
                //   )
                }
              </div>
            </div>
          </div>
        </Layout>
      </div>
    )
  }
}

export default Content

export const query = graphql`
  query($urlPath: String!) {
    markdownRemark(frontmatter: { path: { eq: $urlPath } }) {
      html
      frontmatter {
        path
        name_th
        name_en
        rel_canonical_th
        rel_canonical_en
        description_th
        description_en
        keywords_th
        keywords_en
      }
    }
  }
`
