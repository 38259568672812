import React, { Component } from 'react'
import Select, { components } from 'react-select'
import './style.scss'

const Placeholder = props => {
  return <components.Placeholder {...props} />
}

export default class SelectInput extends Component {
  render() {
    const { selectedOption, handleChange, options, placeholder } = this.props

    return (
      <Select
        isSearchable
        value={selectedOption}
        onChange={handleChange}
        options={options}
        defaultValue={options[0]}
        placeholder={placeholder}
        components={{ Placeholder }}
        styles={{
          placeholder: base => ({
            ...base,
            color: '#495057',
            fontSize: '16px',
            opacity: 0.85,
          }),
        }}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#0078FF',
          },
        })}
      />
    )
  }
}
