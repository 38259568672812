export const INTER_LOGOS = [
  {
    img: 'brand_logo_Sixt.png',
    styles: '',
  },
  {
    img: 'brand_logo_National.png',
    styles: '',
  },
  {
    img: 'brand_logo_BizCar.png',
    styles: '',
  },
  {
    img: 'brand_logo_hertz.png',
    styles: '',
  },
  {
    img: 'brand_logo_Thrifty.jpg',
    styles: { maxHeight: 25 },
  },
  {
    img: 'plus5.png',
    styles: '',
  },
]

export const LOCAL_LOGOS = [
  {
    img: 'brand_logo_WorldRent.png',
    styles: { maxHeight: 35 },
  },
  {
    img: 'brand_logo_ChiangMai_wheel.png',
    styles: { maxHeight: 35 },
  },
  {
    img: 'brand_logo_True_leasing.png',
    styles: { maxHeight: 30 },
  },
  {
    img: 'brand_logo_Luv_drive.png',
    styles: { maxHeight: 35 },
  },
  {
    img: 'brand_logo_CEI.jpg',
    styles: { maxHeight: 35 },
  },
  {
    img: 'brand_logo_Khaen_nakhon.png',
    styles: { maxHeight: 35 },
  },
  {
    img: 'brand_logo_DIAMOND.jpg',
    styles: { maxHeight: 35 },
  },
  {
    img: 'brand_logo_ECOCar.jpg',
    styles: { maxHeight: 35 },
  },
  {
    img: 'brand_logo_UBP.png',
    styles: { maxHeight: 35 },
  },
  {
    img: 'brand_logo_NST.jpg',
    styles: { maxHeight: 35 },
  },
  {
    img: 'brand_logo_PATTAYA.jpg',
    styles: { maxHeight: 35 },
  },
  {
    img: 'plus500.png',
    styles: '',
  },
]
