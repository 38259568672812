import React from 'react'
import styled from 'styled-components'
import './style.scss'

export const ComplaintPolicy = () => {
  return (
    <Container>
      <div>
        <p>
          <strong>มาตรการคุ้มครองและรักษาความลับของผู้ร้องเรียน</strong>
        </p>
        &emsp;เพื่อเป็นการคุ้มครองสิทธิของผู้ร้องเรียนและผู้ให้ข้อมูลที่กระทำโดยเจตนาสุจริตทาง{' '}
        <a rel="noreferrer" href="http://www.drivehub.co/">
          Drivehub
        </a>{' '}
        จะปกปิดชื่อ ที่อยู่ หรือข้อมูลใด ๆ
        ที่สามารถระบุตัวผู้ร้องเรียนหรือผู้ให้ข้อมูลได้
        และเก็บรักษาข้อมูลของผู้ร้องเรียนและผู้ให้ข้อมูลไว้เป็นความลับ
        โดยจำกัดเฉพาะผู้รับผิดชอบในการดำเนินการตรวจสอบเรื่องร้องเรียนเท่านั้นที่จะเข้าถึงข้อมูลดังกล่าวได้
        ทั้งนี้
        ผู้ได้รับข้อมูลจากการปฏิบัติหน้าที่ที่เกี่ยวข้องกับเรื่องร้องเรียน
        มีหน้าที่เก็บรักษาข้อมูล ข้อร้องเรียน
        และเอกสารหลักฐานของผู้ร้องเรียนและผู้ให้ข้อมูลไว้เป็นความลับ
        ห้ามเปิดเผยข้อมูลแก่บุคคลอื่นที่ไม่มีหน้าที่เกี่ยวข้อง
        เว้นแต่เป็นการเปิดเผยตามหน้าที่ที่กฎหมายกำหนด
      </div>
      <div>
        <p>
          <strong>การดำเนินการเมื่อได้รับเรื่องร้องเรียน</strong>
        </p>
        &emsp;
        <a rel="noreferrer" href="http://www.drivehub.co/">
          Drivehub
        </a>{' '}
        จะเป็นผู้พิจารณาเรื่องร้องเรียนที่ได้รับทันที
        และแจ้งให้หน่วยงานที่เกี่ยวข้องดำเนินการสอบสวนข้อเท็จจริงเกี่ยวกับเรื่องที่ได้รับ
        การร้องเรียนและดำเนินการแก้ไขอย่างเหมาะสม{' '}
        <a rel="noreferrer" href="http://www.drivehub.co/">
          Drivehub
        </a>{' '}
        จะติดตามผลความคืบหน้าเป็นระยะ
        ซึ่งหน่วยงานที่เกี่ยวข้องจะแจ้งผลการดำเนินงานภายในระยะเวลาที่เหมาะสมให้แก่ผู้ร้องเรียนทราบ
        โดยไม่มีค่าใช้จ่าย
      </div>
      <div>
        <p>
          <strong>ช่องทางการรับเรื่องร้องเรียน</strong>
        </p>
        &emsp;ผู้ร้องเรียนจะต้องระบุรายละเอียดของเรื่องที่จะร้องเรียน พร้อมชื่อ
        ที่อยู่ และหมายเลขโทรศัพท์ที่สามารถติดต่อได้ และส่งมาได้ 2 ช่องทาง คือ{' '}
        <br />
        &emsp;&emsp;ช่องทางที่ 1 ทางจดหมายอิเล็คทรอนิกส์ ไปยังที่อยู่อีเมล :{' '}
        <a href="mailto:support@drivehub.co">support@drivehub.co</a>
        <br />
        &emsp;&emsp;ช่องทางที่ 2 ผ่านทาง LINE@ Official Account
        โดยสามารถเพิ่มเพื่อนได้จากไอดีไลน์ :{' '}
        <a rel="noreferrer" href="https://line.me/ti/p/%40drivehub">
          @drivehub
        </a>
        <br />
        &emsp;หรือผู้ร้องเรียนสามารถติดต่อแจ้งเรื่องร้องเรียนผ่านทางโทรศัพท์ได้โดยตรงกับเจ้าหน้าที่
        โดยติดต่อที่เบอร์
        <a rel="noreferrer" href="tel:+6620385222">
          {' '}
          02-038-5222
        </a>{' '}
        (ช่วงเวลา 8:00-22:00)
      </div>
      <div>
        <p>
          <strong>ช่องทางการระงับข้อพิพาทโดยกลไกภายนอก</strong>
        </p>
        &emsp;หากกระบวนการแก้ไขข้อพิพาทโดยผู้ประกอบการไม่เป็นที่พอใจของลูกค้า
        ลูกค้าสามารถใช้ช่องทางภายนอกที่ลูกค้าสามารถดำเนินการระงับข้อพิพาทได้
        โดยติดต่อไปยัง <br />
        &emsp;กรมพัฒนาธุรกิจการค้า 563 ถนนนนทบุรี ตำบลบางกระสอ อำเภอเมือง
        จังหวัดนนทบุรี 11000 <br />
        &emsp;&emsp;โทร.{' '}
        <a rel="noreferrer" href="tel:+6625475960">
          0-2547-5960
        </a>{' '}
        โทรสาร.{' '}
        <a rel="noreferrer" href="tel:+6625475973">
          0-2547-5973
        </a>{' '}
        (กองพาณิชย์อิเล็กทรอนิกส์ e-Commerce) <br />
        &emsp;&emsp;อีเมล :{' '}
        <a href="mailto:e-commerce@dbd.go.th">support@drivehub.co</a> เว็บไซด์ :{' '}
        <a href="http://www.dbd.go.th/" rel="noreferrer">
          www.dbd.go.th
        </a>{' '}
        <br />
      </div>
    </Container>
  )
}

const Container = styled.div`
  p {
    font-size: 18px;
    margin-bottom: 0px;
  }
  > div {
    padding-bottom: 8px;
  }
  @media screen and (max-width: 768px) {
    padding: 0px 8px;
  }
`

export default ComplaintPolicy
