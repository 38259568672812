import React, { Component } from 'react'
import axios from 'axios'
import SelectInput from '../select-form'
import { LoadingWheel } from '../loading'
import ThankYou from '../thank-you'
import { API_LEGACY_LINK } from '../../../config'
import province from '../../contents/province.json'
import './style.scss'

class ListYourCar extends Component {
  state = {
    selectedOption: '',
    isLoading: false,
    submitSuccess: false,
    options: [],
  }
  componentDidMount() {
    let options = []
    province[this.props.prefix].forEach(el => {
      const obj = {
        value: el,
        label: el,
      }
      options.push(obj)
    })
    this.setState({ options })
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption })
  }

  renderTopSection = (prefix, content) => {
    return (
      <div className="col-12">
        <div className="form-group">
          <label>{content.form[prefix].input[0].name}</label>
          <SelectInput
            prefix={prefix}
            handleChange={this.handleChange}
            selectedOption={this.state.selectedOption}
            options={this.state.options}
            placeholder="กรุณาเลือกจังหวัด"
          />
        </div>
        <div className="form-group">
          <label>{content.form[prefix].input[1].name}</label>
          <input
            type="text"
            name="company_name"
            className="form-control form-input"
            placeholder={content.form[prefix].input[1].placeholder}
            required
          />
        </div>
        <div className="form-group">
          <label>{content.form[prefix].input[2].name}</label>
          <textarea
            type="text"
            name="address"
            className="form-control form-input"
            placeholder={content.form[prefix].input[2].placeholder}
            rows="3"
            required
          />
        </div>
        <div className="form-group">
          <label>{content.form[prefix].input[3].name}</label>
          <input
            type="number"
            name="fleet_size"
            min="0"
            step="1"
            className="form-control form-input"
            placeholder={content.form[prefix].input[3].placeholder}
            required
          />
        </div>
      </div>
    )
  }
  renderBottomSection = (prefix, content) => {
    return (
      <div className="col-12">
        <div className="form-group">
          <label>{content.form[prefix].input[4].name}</label>
          <input
            type="text"
            name="contact_name"
            className="form-control form-input"
            placeholder={content.form[prefix].input[4].placeholder}
            required
          />
        </div>
        <div className="form-group">
          <label>{content.form[prefix].input[5].name}</label>
          <input
            type="phone"
            name="contact_number"
            pattern="[0-9]{10}"
            className="form-control form-input"
            placeholder={content.form[prefix].input[5].placeholder}
            required
          />
        </div>
        <div className="form-group">
          <label>{content.form[prefix].input[6].name}</label>
          <input
            type="email"
            name="contact_email"
            className="form-control form-input"
            placeholder={content.form[prefix].input[6].placeholder}
            required
          />
        </div>
      </div>
    )
  }

  onSubmit = event => {
    event.preventDefault()
    this.setState({ isLoading: true })
    const data = new FormData(event.target)
    const rawData = {}
    data.forEach((el, key) => {
      const objKey = `[partner_requests]${key}`
      rawData[objKey] = el
    })
    const objData = Object.assign(rawData, {
      '[partner_requests]city': this.state.selectedOption.value,
    })
    const param = Object.entries(objData)
      .map(([key, val]) => `${key}=${val}`)
      .join('&')

    axios({
      method: 'post',
      url: `${API_LEGACY_LINK}/partner_requests?${param}`,
    })
      .then(result => {
        if ((result.status = 200)) {
          this.setState({
            isLoading: false,
            submitSuccess: true,
          })
        }
      })
      .catch(error => console.log(error))
  }

  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  render() {
    const { prefix, content } = this.props
    const { isLoading, submitSuccess } = this.state
    const description = content.description[prefix]

    return (
      <React.Fragment>
        {isLoading ? (
          <div style={{ minHeight: 350, display: 'flex', alignItems: 'center' }}>
            <LoadingWheel height={40} width={40} />
          </div>
        ) : submitSuccess ? (
          <ThankYou isListYourCarPage={true} />
        ) : (
          <React.Fragment>
            {description}
            <div className="form-container">
              <form onSubmit={this.onSubmit} id="list-your-car-form">
                <input type="hidden" name="list-your-car-form" value="list-your-car" />
                <input type="hidden" name="city" />
                <div className="form-section">
                  <h2>{content.form[prefix].header[0]}</h2>
                  {this.renderTopSection(prefix, content)}
                  <h2>{content.form[prefix].header[1]}</h2>
                  {this.renderBottomSection(prefix, content)}
                  <div className="col-12 text-right">
                    <button className="button--submit" type="submit" id="btn-partner-request">
                      ร่วมเป็นพันธมิตรกับเรา
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

export default ListYourCar
