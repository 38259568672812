import React from 'react'
import styled from 'styled-components'
import './style.scss'

export default prefix => {
  const content = {
    th: [
      'คุณสามารถพบคำตอบสำหรับคำถามทั่วๆไปได้ที่ส่วนความช่วยเหลือ',
      'คลิกที่นี่',
      'หรือสามารถอีเมล์มาถามเราได้ที่',
      'หรือโทรหาเราที่',
      <>
        ไดรฟ์ฮับพยายามตอบทุกข้อสงสัยของคุณภายใน 24 ชม. ตลอดช่วงเวลาทำการของเรา
        <b>ทุกวัน 08:00 - 22:00 น.</b>
      </>,
    ],
    en: [
      'You can find answers for any general questions in the help section (FAQs):',
      'Here',
      'If you need answers to a specific questions, send us an e-mail at',
      'or call us at',
      <>
        We do our best to answer and resolve your query within 24 hours.
        <b> Business hours: Everyday 08:00 - 22:00</b>
      </>,
    ],
  }

  const text = content[prefix]
  return (
    <Container className="content-page">
      <p>
        {text[0]} <a href={`/${prefix}/help-center`}>{text[1]}</a>
        <br />
        {
          text[2]
        } <a href="mailto:support@drivehub.co">support@drivehub.co</a> {text[3]}{' '}
        <a href="tel:020385222">02 038 5222</a>
      </p>
      <p>{text[4]}</p>
    </Container>
  )
}

const Container = styled.div`
  margin-top: 40px;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`
