import React from 'react'
import styled from 'styled-components'
import './style.scss'

export default prefix => {
  const json = {
    th: [
      'ขั้นตอนการจองรถ',
      {
        เตรียมตัวก่อนจองรถ: [
          'เตรียมข้อมูลที่ต้องใช้สำหรับการจองรถให้พร้อม',
          'ชื่อของคุณ',
          'วันที่และเวลารับรถ',
          'สถานที่รับรถ',
          'เบอร์โทรศัพท์',
          'อีเมล์',
          'ข้อมูลอื่นๆ เช่น เที่ยวบิน, ชื่อโรงแรม, Line ID (ถ้ามี)',
        ],
        id: 'prepare',
      },
      {
        'เลือกสถานที่รับรถ วันและเวลารับ-คืนรถ': [
          <>
            เลือกวันและเวลารับคืนรถ หลังจากนั้นกดที่ปุ่ม{' '}
            <b>ค้นหารถเช่าราคาดีที่สุด</b>
          </>,
          require('../../static/assets/content/how-to-book/1_search_screen.png'),
        ],
        id: 'places',
      },
      {
        เลือกรถว่างคันที่ถูกใจ: [
          <>
            ระบบจะโชว์รถว่างพร้อมราคา
            ตามวันเวลาที่ลูกค้าเลือกเข้ามาท่านสามารถเลือกรถที่ถูกใจ กดปุ่ม{' '}
            <b>อ่านรายละเอียดก่อนจอง</b> เพื่อเข้าสู้หน้ารายละเอียดของรถคันนั้น
          </>,
          require('../../static/assets/content/how-to-book/2_search_result_screen.png'),
        ],
        id: 'selected-car',
      },
      {
        อ่านรายละเอียดรถและทำการจองรถคันที่เลือก: [
          <>
            อ่านรายละเอียดของรถคันนั้น ๆ เช่น ราคารายวัน ราคารวม
            ค่ารับส่งรถในจุดรับรถที่เลือก เงินมัดจำ ของรถ หลังจากนั้นกดปุ่ม{' '}
            <b>เลือกรถคันนี้</b>
          </>,
          require('../../static/assets/content/how-to-book/3_car_detail_screen.png'),
          'กรอกรายละเอียดข้อมูลเพื่อให้เจ้าหน้าที่ติดต่อกลับไป และกดปุ่ม ยืนยัน เพื่อส่งการจองให้กับทางเจ้าหน้าที่ทางเว็บไซต์',
          require('../../static/assets/content/how-to-book/4_driver_info_screen.png'),
        ],
        id: 'car-detail',
      },
      {
        ยืนยันการเช่ารถ: [
          <>
            หลังจาก กด ยืนยัน เรียบร้อยแล้ว ท่านจะได้รับหมายเลขการจอง
            และอีเมลตอบรับจากระบบ
            และรอทางเจ้าหน้าที่จากบริษัทรถเช่าที่ท่านเลือกติดต่อกลับ
            <b>ภายใน 15 นาที (ในเวลาทำการ 8:30-20:30)</b>{' '}
            หากทางเจ้าหน้าที่ได้ติดต่อกลับมา และมีการคอนเฟิร์ม ส่งเอกสาร
            และโอนเงินจองเรียบร้อย ท่านจะได้รับข้อความและ อีเมลยืนยันการจอง
            ถือว่า การจองของท่านได้เสร็จสมบูรณ์แล้ว
          </>,
          require('../../static/assets/content/how-to-book/5_confirm_screen.png'),
        ],
        id: 'confirm',
      },
    ],
    en: [
      'Booking Procedures',
      {
        'Preperation:': [
          'In order to book a car with us, please make sure you prepare the following information:',
          'Your name',
          'Your intended dates and time of rental',
          'Location',
          'Contact number',
          'Email',
          'Other information: flight itinerary, hotel name, Line ID (optional)',
        ],
        id: 'prepare',
      },
      {
        'Search:': [
          <>
            Select a location from the provided list and enter you intended date
            and time of rental. Once you have done this, press <b>"Search"</b>
          </>,
          require('../../static/assets/content/how-to-book/1_search_screen.png'),
        ],
        id: 'places',
      },
      {
        'Selection:': [
          <>
            This system runs through our database to find you all available cars
            on your selected date and time. Choose a car you like by pressing
            the button: <b>"View Details"</b> located inside the info box of
            each car, this will take you inside the car full information page.
          </>,
          require('../../static/assets/content/how-to-book/2_search_result_screen.png'),
        ],
        id: 'selected-car',
      },
      {
        'Select and Book:': [
          <>
            Read the car details, such as price per day, total price, fee for
            receiving – returning the car at the selected location, car deposit,
            and click the <b>"Select"</b> button.
          </>,
          require('../../static/assets/content/how-to-book/3_car_detail_screen.png'),
          'Enter your contact details inside the "Drivers Details" Box for and press: Check and Comfirm, to submit you booking request.',
          require('../../static/assets/content/how-to-book/4_driver_info_screen.png'),
        ],
        id: 'car-detail',
      },
      {
        'Booking Creation:': [
          <>
            Once you have comfirmed your contact details and submit your booking
            request, you will be provided with a booking reference number. We
            will also email you a copy of this. A staff from the rental operator
            will be in contact winthin 15 minutes (business hours 8:30-20:30)
            after booking creation
            <b> winthin 15 minutes (business hours 8:30-20:30)</b>. Once a staff
            from the rental operator confirms your information, documents, and
            transfer of booking fee, you will receive a message and confirmation
            e-mail that your booking is complete.
          </>,
          require('../../static/assets/content/how-to-book/5_confirm_screen.png'),
        ],
        id: 'confirm',
      },
    ],
  }
  const text = json[prefix]
  const keys = text.slice(1).map(t => Object.keys(t)[0])
  return (
    <Container className="content-page">
      <h2>{text[0]}</h2>
      <ul className="list-step mb-3">
        {keys.map((key, index) => (
          <li key={key}>
            <a href={`#${text.slice(1)[index].id}`}>
              <img
                src={require(`../../static/assets/content/how-to-book/${index +
                  1}_icon.png`)}
                alt={text.slice(1)[index].id}
              />
              <span>{key}</span>
            </a>
          </li>
        ))}
      </ul>
      {text.slice(1).map((section, index) => {
        const title = Object.keys(section)[0]
        const values = section[title]
        return (
          <div className="mb-3" key={title} id={section.id}>
            <h2>
              {/* eslint-disable-next-line */}
              <a>{Object.keys(section)[0]}</a>
            </h2>
            <p>{values[0]}</p>
            {index === 0 && (
              <div style={{ display: 'flex' }}>
                <ul style={{ flex: 1 }}>
                  {values.slice(1, 4).map(text => (
                    <li key={text}>
                      <img
                        className="mr-2"
                        src="/assets/green_check_icon.png"
                        width={13}
                        alt="Check mark"
                      />
                      {text}
                    </li>
                  ))}
                </ul>
                <ul style={{ flex: 1 }}>
                  {values.slice(4).map(text => (
                    <li key={text}>
                      <img
                        className="mr-2"
                        src="/assets/green_check_icon.png"
                        width={13}
                        alt="Check mark"
                      />
                      {text}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {index > 0 &&
              values.slice(1).map(text => {
                if (text.includes('.png')) {
                  return (
                    <img className="mb-4" src={text} key={text} alt={title} />
                  )
                }
                return <p key={text}>{text}</p>
              })}
          </div>
        )
      })}
    </Container>
  )
}

const Container = styled.div`
  p {
    margin-bottom: 10px;
  }
  li {
    list-style: none;
    a {
      text-decoration: none;
      color: #30374b;
      position: relative;
      img {
        padding-right: 10px;
        width: 33px;
        z-index: 1;
      }
      &::after {
        content: '';
        position: absolute;
        left: 11px;
        bottom: -30px;
        height: 25px;
        border-left: 1px dashed #c5c5c5;
        z-index: 0;
      }
    }
    &:last-child {
      a {
        &::after {
          display: none;
        }
      }
    }
  }
  .list-step {
    li {
      padding-bottom: 15px;
    }
  }
  ul {
    margin: 0px;
  }
`
