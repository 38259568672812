import React from 'react'
import styled from 'styled-components'
import Tab, { TabPane } from '../../tab'
import IdCardIcon from '../../../../static/assets/content/id_card.svg'
import DrivingLicenseIcon from '../../../../static/assets/content/driving_lic.svg'
import DocumentIcon from '../../../../static/assets/content/documents.svg'
import CreditCardIcon from '../../../../static/assets/content/creditcard.svg'
import CreditSymbol from '../../../../static/assets/content/credit_symbol.png'

const question = {
  icon: 'flaticon-document',
  question: 'เอกสารต้องใช้อะไรบ้าง?',
  answer: props => {
    return (
      <Container>
        <Tab>
          <TabPane
            title={
              <span>
                ชำระด้วยเงินสด หรือ <br /> บัตรเครดิต ณ วันรับรถ
              </span>
            }
          >
            <div style={{ marginBottom: 70 }}>
              <p>
                ทางบริษัทรถเช่าอาจเรียกเก็บค่าเช่าล่วงหน้าเพื่อล็อคคิวรถเป็นจำนวนเงิน 500-1000 บาท{' '}
              </p>
              <div className="row justify-content-center">
                <DocumentBlock
                  index={1}
                  title="บัตรประชาชน หรือ Passport"
                  description="อายุไม่น้อยกว่า 21 ปี 
                  และบัตรยังไม่หมดอายุ"
                  src={IdCardIcon}
                />
                <DocumentBlock
                  index={2}
                  title="ใบขับขี่"
                  description="ยังไม่หมดอายุ และได้รับมาไม่ต่ำกว่า 1 ปี"
                  src={DrivingLicenseIcon}
                />
                <DocumentBlock
                  index={3}
                  title="เอกสารประกอบอื่นๆ"
                  description="อาจมีการขอเอกสารของผู้เช่าเพิ่มเติมจากบริษัทรถเช่า"
                  src={DocumentIcon}
                />
              </div>
              <div className="faq_more_info mt-5">
                <a
                  href="https://www.drivehub.co/th/documents"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="more_info_box"
                >
                  ดูเอกสารที่ต้องใช้แบ่งตามจังหวัดและลักษณะการเช่า{' '}
                  <i className="flaticon-next more_info_icon" />
                </a>
              </div>
            </div>
          </TabPane>

          <TabPane title="ชำระด้วยบัตรเครดิตบนหน้าเว็บ*">
            <div>
              <div className="row justify-content-center">
                <DocumentBlock
                  index={1}
                  title="บัตรประชาชน หรือ Passport"
                  description="อายุไม่น้อยกว่า 23 ปี 
                  และบัตรยังไม่หมดอายุ"
                  src={IdCardIcon}
                />
                <DocumentBlock
                  index={2}
                  title="ใบขับขี่**"
                  description="ยังไม่หมดอายุ และได้รับมาไม่ต่ำกว่า 1 ปี"
                  src={DrivingLicenseIcon}
                />
                <DocumentBlock index={3} title="บัตรเครดิต***" src={CreditCardIcon} />
              </div>
              <div className="faq_note mt-3">
                <u>หมายเหตุ</u>
                <div>
                  *เฉพาะรถที่มีสัญลักษณ์บัตรเครดิตเท่านั้น{' '}
                  <img src={CreditSymbol} height="47" alt="Credit Card" />
                </div>
                <div>
                  **ในกรณีที่ผู้เช่า/ผู้ขับขี่ถือสัญชาติอื่นที่ไม่ใช่สัญชาติไทย
                  จะต้องใบอนุญาตขับรถระหว่างประเทศ
                  หรือใบอนุญาตขับรถของประเทศที่ทางประเทศไทยอนุญาตให้ใช้เพื่อการขับขี่ภายในประเทศไทยได้
                  แต่ใบอนุญาตดังกล่าวจะต้องมีชื่อของผู้ขับขี่เป็นภาษาอังกฤษกำกับอยู่
                </div>
                <div>
                  ***ต้องมีวงเงินในบัตรเพียงพอกับสำหรับค่าเช่าและค่ามัดจำเพื่อประกันความเสียหาย
                  ที่บริษัทรถเช่ากำหนด
                </div>
              </div>
            </div>
          </TabPane>
        </Tab>
      </Container>
    )
  },
}

const Container = styled.div`
  padding: 15px;

  .block {
    flex-direction: column;
    border: 1px solid #eee;
    padding: 10px;
    height: 150px;
    width: calc(100% - 15px);
    margin: 0;
    display: flex;
    font-size: 14px;
    text-align: center;
    img {
      height: 80px;
    }
    .count {
      position: absolute;
      color: #0078ff;
      font-size: 20px;
      font-weight: 700;
    }
  }
`
const DocumentBlock = ({ src = '', title = '', description = '', index = '' }) => (
  <div className="col-sm-4 ">
    <div className="block">
      <div style={{ display: 'flex' }}>
        <span className="count">{index}</span>
        <img className="m-auto" src={src} height="35" width="48" alt="Content" />
      </div>
      <h6 className="document_title" style={{ color: '#0078ff', marginBottom: 10 }}>
        {title}
      </h6>
      <small>{description}</small>
    </div>
  </div>
)

export default question
