import React from 'react'
import styled from 'styled-components'
import Tab, { TabPane } from '../components/tab'
import TabTitle from '../components/tab-title'
import './style.scss'

export default prefix => {
  const json = {
    th: {
      เช่ารถที่ไม่ต้องใช้บัตรเครดิต: [
        'หลังทำการจองสำเร็จ จะมีเจ้าหน้าที่จากบริษัทรถเช่าติดต่อกลับ ภายในไม่เกิน 15 นาที เพื่อยืนยันเอกสารและยืนยันการจองอีกครั้ง ท่านสามารถนัดแนะจุดรับรถกับบริษัทรถเช่าได้โดยตรง ไม่ว่าจะเป็นที่สนามบิน ในตัวเมือง หรือที่โรงแรม (หากท่านต้องการรับหรือคืนรถนอกเหนือพื้นที่ที่กำหนด ทางบริษัทรถเช่าจะเรียกเก็บค่าบริการเพิ่มเติม ทั้งนี้ขึ้นอยู่กับเงื่อนไขของแต่ละบริษัท)',
        'โปรดทราบ',
        <>
          เจ้าหน้าที่จะขอเอกสารเพิ่มเติมเพื่อใช้ในการรับรถ
          ซึ่งประเภทของเอกสารที่ใช้ในการรับรถ
          <br />
          ขึ้นอยู่กับประเภทการจองของคุณ
        </>,
        <a href={`/${prefix}/documents/#cash`}>
          อ่านเพิ่มเติมเกี่ยวกับเอกสารที่ต้องใช้
        </a>,
      ],
      เช่ารถที่ต้องใช้บัตรเครดิต: [
        'คุณสามารถรับรถได้ที่เคาน์เตอร์ที่อยู่ภายในตัวอาคารสนามบิน ซึ่งโดยปกติแล้วเคาน์เตอร์จะอยู่บริเวณทางออกหลังรับกระเป๋าเสร็จแล้ว สำหรับบางสนามบินที่ไม่มีเคาน์เตอร์อยู่ด้านใน จะมีเจ้าหน้าที่ของบริษัทรับรถนั้นๆรอรับท่านที่ทางออกผู้โดยสารขาเข้า',
        'คุณจะไม่สามารถรับรถได้ถ้าไม่มีบัตรเครดิต',
        'บริษัทรถเช่ารายใหญ่ต้องการบัตรเครดิตเพื่อล๊อควงเงิน คุณจะต้องนำบัตรเครดิตไปชำระค่าประกันการเช่า ณ เคาน์เตอร์รับรถ โปรดเช็ควงเงินคงเหลือในบัตรของคุณให้พอกับค่าประกันการเช่า',
      ],
    },
    en: {
      'Cash or Credit Card (at Pick-up)': [
        'Once yove have created your booking, a staff from the car rental operator will be in contact within 15 minutes to request for your identification documents and any additional documents required. You can also pre-arrange the location of vehicle pick-up with the rental operator whether that would be at the airport, city or hotel. (If you want to pick-up and drop-off the vehicle at a location outside the operator specified service area, the rental operator may collect additional vehicle delivery service fees subject to the terms and conditions of each individual operator.)',
        'Please note:',
        <>
          In order for your booking to be approved by the rental operator, you
          will need to provide your identification information and
          documentations. This depends on the type of bookings of the vehicle.
        </>,
        <a href={`/${prefix}/documents/#cash`}>
          Required rental documents. Read more{' '}
        </a>,
      ],
      'Credit Card (on Drivehub Website)*': [
        'You can pick-up your vehicle at airport counte, generally located near the exit after baggage collection. Please note that a phisical counter inside the terminal may not be available in some airport. In this case there will be a staff from the rental operator waiting for you near ther exit of the airport arrival terminal.',
        'คุณจะไม่สามารถรับรถได้ถ้าไม่มีบัตรเครดิต',
        'บริษัทรถเช่ารายใหญ่ต้องการบัตรเครดิตเพื่อล๊อควงเงิน คุณจะต้องนำบัตรเครดิตไปชำระค่าประกันการเช่า ณ เคาน์เตอร์รับรถ โปรดเช็ควงเงินคงเหลือในบัตรของคุณให้พอกับค่าประกันการเช่า',
      ],
    },
  }
  const text = json[prefix]
  return (
    <Container className="content-page">
      <Tab>
        {Object.keys(text).map((title, index) => {
          const values = text[title]
          return (
            <TabPane
              title={<TabTitle type={index === 0 ? 'local' : 'inter'} />}
              key={title}
              hash={index === 0 ? 'cash' : 'credit'}
            >
              <h2>{title}</h2>
              <p>{values[0]}</p>
              {index === 0 ? (
                <div className="box--note">
                  <h2>{values[1]}</h2>
                  <p>{values[2]}</p>
                  <ul>
                    <li>{values[3]}</li>
                  </ul>
                </div>
              ) : (
                <CreditCardWarning>
                  <div>
                    <i className="flaticon-warning" />
                  </div>
                  <div>
                    <h2>{values[1]}</h2>
                    <p>{values[2]}</p>
                  </div>
                </CreditCardWarning>
              )}
            </TabPane>
          )
        })}
      </Tab>
    </Container>
  )
}

const CreditCardWarning = styled.div`
  display: flex;
  background-color: #f8d7da;
  border-radius: 2px;
  padding: 15px;
  color: #721c24;
  div:nth-child(1) {
    margin-right: 20px;
    margin-left: 10px;
    i::before {
      font-size: 28px;
    }
  }
  div:nth-child(2) {
    h2 {
      margin-bottom: 5px;
      font-size: 18px;
    }
    p {
      margin-bottom: 0;
    }
  }
`

const Container = styled.div`
  .box--note {
    background-color: #e6eef8;
    border-radius: 2px;
    padding: 15px;
    h2 {
      margin-left: 5px;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 5px;
      margin-left: 5px;
      color: #585858;
    }
    li {
      margin: 0px;
      padding: 0px;
    }
    ul {
      margin-bottom: 0px;
    }
    a {
      text-decoration: underline;
    }
  }
`
