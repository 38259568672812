const size = 14

const questions = Array(size)
  .fill()
  .map((_, index) => {
    const question = require(`/${index + 1}`).default
    return {
      ...question,
      answer: props =>
        typeof question.answer === 'function' ? question.answer(props) : question.answer,
    }
  })

export default questions
