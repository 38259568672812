import React from 'react'

const question = {
  icon: '',
  question: 'สามารถรับและส่งคืนรถข้ามจังหวัดได้มั้ย?',
  answer: props => (
    <div style={{ padding: 15 }}>
      <b>
        <u>หากท่านทำการจองรถที่ไม่ต้องใช้บัตรเครดิต</u>
      </b>
      <div>
        การบริการรับและส่งคืนรถข้ามจังหวัด จะมีให้บริการในเพียงจังหวัด
        ดังต่อไปนี้
      </div>
      <ul className="indent-all">
        <li>เชียงใหม่ - เชียงราย มีค่าบริการเพิ่มเติมจากราคาเช่า 2,500 บาท</li>
        <li>
          เชียงใหม่ – แม่ฮ่องสอน มีค่าบริการเพิ่มเติมจากราคาเช่า 2,000 บาท
        </li>
        <li>เชียงใหม่ – ลำปาง มีค่าบริการเพิ่มเติมจากราคาเช่า 1,500 บาท</li>
        <li>ขอนแก่น - อุดร มีค่าบริการเพิ่มเติมจากราคาเช่า 1,500 บาท</li>
        <li>อุบล-ขอนแก่น มีค่าบริการเพิ่มเติมจากราคาเช่า 3,500 บาท</li>
        <li>
          อุบล-ขอนแก่น (เช่าขั้นต่ำ3วัน ) มีค่าบริการเพิ่มเติมจากราคาเช่า 1,800
          บาท
        </li>
        <li>พัทยา – กรุงเทพฯ มีค่าบริการเพิ่มเติมจากราคาเช่า 1,500 บาท</li>
        <li>นครพนม - สกลนคร มีค่าบริการเพิ่มเติมจากราคาเช่า 800 บาท</li>
        <li>กระบี่-ตรัง มีค่าบริการเพิ่มเติมจากราคาเช่า 1,500 บาท</li>
        <li>กระบี่- ภูเก็ต มีค่าบริการเพิ่มเติมจากราคาเช่า 1,200 บาท</li>
        <li>ตรัง - หาดใหญ่ มีค่าบริการเพิ่มเติมจากราคาเช่า 1,500 บาท</li>
      </ul>
      <p>
        <b>
          ค่าบริการโดยประมาณ
          อาจจะมีการเปลี่ยนแปลงขึ้นอยู่กับเงื่อนไขแต่ละบริษัทรถเช่า
        </b>
      </p>
      <div>
        ทั้งนี้การบริการรับและส่งคืนรถข้ามจังหวัดจะ
        <b>มีให้บริการเพียงบางบริษัทเท่านั้น</b>{' '}
        หากลูกค้ามีความประสงค์ใช้บริการรับและส่งคืนรถข้ามจังหวัด
        กรุณาติดต่อเจ้าหน้าที่ Drivehub ที่เบอร์ 02-038-5222 หรือ Line Id:
        @Drivehub
      </div>
    </div>
  ),
}

export default question
