import React from 'react'

const question = {
  icon: '',
  question: 'สามารถขอใบกำกับภาษีได้หรือไม่?',
  answer: props => (
    <div style={{ padding: 15 }} className="indent">
      สำหรับท่านที่ต้องการใบกำกับภาษี สามารถติดต่อสอบถามได้จากเจ้าหน้าที่ Drivehub ที่เบอร์
      02-038-5222 หรือ Line Id: @Drivehub
    </div>
  ),
}

export default question
