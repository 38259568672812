import React from 'react'
import styled from 'styled-components'
import Tab, { TabPane } from '../components/tab'
import TabTitle from '../components/tab-title'
import check from '../../static/assets/content/how-to-book/green_check_icon.png'
import './style.scss'

export default prefix => {
  const json = {
    th: {
      cashTitle: 'เช่ารถที่ไม่ต้องใช้บัตรเครดิต',
      creditTitle: 'เช่ารถที่ต้องใช้บัตรเครดิต*',
      cash: [
        <>
          ทางบริษัทรถเช่าอาจเรียกเก็บค่าเช่าล่วงหน้าเพื่อล็อคคิวรถเป็นจำนวนเงิน{' '}
          <b>500-1000 บาท</b>
        </>,
        {
          เอกสารสำคัญ: [
            {
              src: require('../../static/assets/content/id_card.svg'),
              title: 'บัตรประชาชน หรือ Passport',
              description: (
                <>
                  อายุไม่น้อยกว่า 21 ปี <br />
                  และบัตรยังไม่หมดอายุ
                </>
              ),
            },
            {
              src: require('../../static/assets/content/driving_lic.svg'),
              title: 'ใบขับขี่',
              description: (
                <>
                  ยังไม่หมดอายุ และได้รับมา
                  <br /> ไม่ต่ำกว่า 1 ปี
                </>
              ),
            },
            {
              src: require('../../static/assets/content/documents.svg'),
              title: 'เอกสารประกอบอื่นๆ',
              description: 'อาจมีการขอเอกสารของผู้เช่าเพิ่มเติมจากบริษัทรถเช่า',
            },
          ],
        },
        {
          เอกสารประกอบอื่นๆ: [
            ['พนักงานบริษัท', 'สลิปเงินเดือนล่าสุด และบัตรพนักงาน'],
            [
              'สำหรับนักท่องเที่ยว / ชาวต่างชาติ',
              'ตั๋วเครื่องบิน หรือ ตั๋วโดยสารยานพาหนะต่าง ๆ ที่ท่านเดินทางมาถึงจังหวัดนั้น ๆ',
            ],
            [
              'เจ้าของกิจการ',
              'เอกสารที่ยืนยันตนว่าเป็นเจ้าของกิจการ เช่น ใบจดทะเบียนผู้เสียภาษี, หนังสือจดทะเบียนบริษัท',
            ],
            [
              'นักศึกษา',
              'บัตรนักศึกษา ใบรับรองสถานะนักศึกษา*',
              '*สำหรับผู้เป็นนักศึกษา จำเป็นต้องมีผู้ปกครองค้ำประกัน และต้องเดินทางมาเซ็นสัญญา ณ วันรับรถด้วย',
            ],
            [
              'ใช้รถระหว่างรอซ่อม',
              'เอกสารใบแจ้งซ่อม ใบเคลมประกัน ใบรับรถจากอู่หรือศูนย์บริการ*',
              '*ผู้ให้บริการบางรายอาจขอเอกสารเพิ่มเติม เช่น สลิปเงินเดือน หรือ เอกสารยืนยันว่าเป็นเจ้าของกิจการ',
            ],
          ],
        },
      ],
      credit: [
        {
          เอกสารสำคัญ: [
            {
              src: require('../../static/assets/content/id_card.svg'),
              title: 'บัตรประชาชน หรือ Passport',
              description: (
                <>
                  อายุไม่น้อยกว่า 23 ปี <br />
                  และบัตรยังไม่หมดอายุ
                </>
              ),
            },
            {
              src: require('../../static/assets/content/driving_lic.svg'),
              title: 'ใบขับขี่**',
              description: (
                <>
                  ยังไม่หมดอายุ และได้รับมา
                  <br /> ไม่ต่ำกว่า 1 ปี
                </>
              ),
            },
            {
              src: require('../../static/assets/content/creditcard.svg'),
              title: 'บัตรเครดิต***',
            },
          ],
        },
        'หมายเหตุ',
        <>
          *เฉพาะรถที่มีสัญลักษณ์บัตรเครดิตเท่านั้น <br />
          **ในกรณีที่ผู้เช่า/ผู้ขับขี่ถือสัญชาติอื่นที่ไม่ใช่สัญชาติไทย
          จะต้องใบอนุญาตขับรถระหว่างประเทศ
          หรือใบอนุญาตขับรถของประเทศที่ทางประเทศไทยอนุญาตให้ใช้เพื่อการขับขี่ภายในประเทศไทยได้
          แต่ใบอนุญาตดังกล่าวจะต้องมีชื่อของผู้ขับขี่เป็นภาษาอังกฤษกำกับอยู่
          <br />
          ***ต้องมีวงเงินในบัตรเพียงพอกับสำหรับค่าเช่าและค่ามัดจำเพื่อประกันความเสียหาย
          ที่บริษัทรถเช่ากำหนด
        </>,
      ],
    },
    en: {
      cashTitle: 'Cash or Credit Card (at Pick-up)',
      creditTitle: 'Credit Card (on Drivehub Website)*',
      cash: [
        <>
          Rental operator may collect a lock-in fees to secure your bookings,
          this fees ranges from <b>500-1000 Baht</b>
        </>,
        {
          'Required Documents:': [
            {
              src: require('../../static/assets/content/id_card.svg'),
              title: 'Thai National ID Card or Passport',
              description: (
                <>
                  Minimum age of 21 years of age and the card must still be
                  valid.
                  <br />
                </>
              ),
            },
            {
              src: require('../../static/assets/content/driving_lic.svg'),
              title: 'Driver’s License',
              description: (
                <>
                  Must be valid and obtained for
                  <br /> at least 1 year.
                </>
              ),
            },
            {
              src: require('../../static/assets/content/documents.svg'),
              title: 'Other Supporting Documents*',
              description:
                'Additional documents may be required by the car rental company.',
            },
          ],
        },
        {
          'Addtionals:': [
            [
              'Corporate Employees:',
              'Most recent payslip and employee identification card.',
            ],
            [
              'For Tourists / Foreigners:',
              'Flight itinerary/tickets or other transport types tickets/receipt that you used to travel into the province.',
            ],
            [
              'Business Owners:',
              'Prove of business ownership such as taxpayer registration or company registration letter.',
            ],
            [
              'Students:',
              'Student identification card or status confirming letters of the student*',
              '* Students must have a parent/legal guardian signed the agreement as a guarantor at vehicle pick-up.',
            ],
            [
              'Individuals with vehical in repairs:',
              'Vehicle repair notice, insurance claim letter, vehical pick-up notification from the garage or service center*',
              '* Service providers may require additional documents, such as pay slip or prove of business ownership.',
            ],
          ],
        },
      ],
      credit: [
        {
          'Required Documents:': [
            {
              src: require('../../static/assets/content/id_card.svg'),
              title: 'Thai National ID Card or Passport',
              description: (
                <>
                  Minimum age of 21 years of age and the card must still be
                  valid.
                  <br />
                </>
              ),
            },
            {
              src: require('../../static/assets/content/driving_lic.svg'),
              title: 'Driver’s License',
              description: (
                <>
                  Must be valid and
                  <br /> obtained for at least 1 year.
                </>
              ),
            },
            {
              src: require('../../static/assets/content/creditcard.svg'),
              title: 'Credit Card***',
            },
          ],
        },
        'Notes:',
        <>
          * For cars with visible credit card symbol only.
          <br />
          <br />
          ** In the case that the lessee/driver is not a citizen of the Kingdom
          of Thailand and holds a valid foreign passport, the driver must also
          provide a valid international driving permit (IDP) or a driver's
          license of countries permitted by the Kingdom of Thailand. The stated
          official documents must contain the driver's name in English.
          <br />
          <br />
          *** Your credit card must have sufficient credit covering both rental
          costs and accidental damage deposits as determined by each individual
          rental operator.
        </>,
      ],
    },
  }
  const text = json[prefix]
  return (
    <Container className="content-page">
      <Tab>
        <TabPane hash="cash" title={<TabTitle type="local" />}>
          <p>{text.cash[0]}</p>
          {Object.keys(text.cash[1]).map(key => {
            const title = key
            const values = text.cash[1][key]
            return (
              <div className="mb-4">
                <h2>{title}</h2>
                <div className="row justify-content-center">
                  {values.map(value => (
                    <DocumentBlock key={value.title} {...value} />
                  ))}
                </div>
              </div>
            )
          })}
          {Object.keys(text.cash[2]).map(key => {
            const title = key
            const values = text.cash[2][key]
            return (
              <>
                <h2>{title}</h2>
                <div style={{ padding: '0 10px' }} className="row">
                  {values.map(value => (
                    <div className="col-12 col-sm-6 document--optional">
                      <h6>{value[0]}</h6>
                      <div>
                        <img src={check} alt="Check mark" />
                        {value[1]}
                      </div>
                      <p>{value[2]}</p>
                    </div>
                  ))}
                </div>
              </>
            )
          })}
        </TabPane>
        <TabPane hash="credit" title={<TabTitle type="inter" />}>
          {Object.keys(text.credit[0]).map(key => {
            const title = key
            const values = text.credit[0][key]
            return (
              <>
                <h2>{title}</h2>
                <div className="row justify-content-center">
                  {values.map(value => (
                    <DocumentBlock key={value.title} {...value} />
                  ))}
                </div>
              </>
            )
          })}
          <div className="box--note mt-3">
            <h2>{text.credit[1]}</h2>
            <p>{text.credit[2]}</p>
          </div>
        </TabPane>
      </Tab>
    </Container>
  )
}

const Container = styled.div`
  padding: 15px;
  .box--note {
    background-color: #e6eef8;
    border-radius: 2px;
    padding: 15px;
    margin: 5px;
    p,
    li {
      margin: 0px;
      padding: 0px;
    }
    ul {
      margin-bottom: 0px;
    }
    a {
      text-decoration: underline;
    }
  }

  .document--optional {
    border: 1px solid #eee;
    padding: 15px;
    flex: 0 0 calc(50% - 10px);
    margin: 5px;
    @media (max-width: 576px) {
      flex: 0 0 100%;
    }
    img {
      width: 10px;
      margin-right: 10px;
    }
    p {
      margin: 0px;
    }
    div {
      margin-bottom: 15px;
    }
  }
  .block {
    flex-direction: column;
    border: 1px solid #eee;
    padding: 10px;
    height: 150px;
    width: calc(100% - 10px);
    @media (max-width: 576px) {
      width: 100%;
    }
    margin: 5px;
    display: flex;
    font-size: 14px;
    text-align: center;
    justify-content: space-evenly;
    align-items: center;
    h6 {
      margin: 0px;
    }
    .document_title {
      color: #0078ff;
    }
  }
`
const DocumentBlock = ({ src = '', title = '', description = '' }) => (
  <div className="col-sm-4 ">
    <div className="block">
      <img src={src} height="35" width="48" alt="Content" />
      <h6 className="document_title">{title}</h6>
      <small>{description}</small>
    </div>
  </div>
)
