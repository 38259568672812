import React from 'react'
import styled from 'styled-components'
import Tab, { TabPane } from '../components/tab'
import TabTitle from '../components/tab-title'
import './style.scss'
import InsuranceTables from '../components/insurance-table'
export default prefix => {
  const content = {
    th: [
      'รถทุกคันในระบบของ Drivehub มีประกันภัยชั้น 1 ขั้นพื้นฐาน (ซึ่งรวมอยู่ในค่าเช่าแล้ว) แต่จะไม่รวมค่าเสียหายส่วนแรกในกรณีที่ลูกค้าเกิดอุบัติเหตุโดยท่านเป็นฝ่ายผิดหรือไม่มีคู่กรณี',
      'หากเกิดอุบัติเหตุและท่านไม่ซื้อประกันเพิ่มเติมกับบริษัทรถเช่า โดยท่านเป็นฝ่ายผิดหรือไม่มีคู่กรณีบริษัทรถเช่าจะเรียกเก็บค่าเสียหาย(“ค่าเสียหายส่วนแรก”) ระหว่าง 1,000 - 50,000 บาท ในอัตราอ้างอิงตามประเภทรถดังนี้',
      <>
        หากบริษัทประกันภัยประเมินราคาแล้ว ได้ความว่า{' '}
        <b>
          ค่าเสียหายเกินกว่าวงเงินคุ้มครองที่กำหนดไว้
          ผู้เช่า/ผู้ยืมตกลงรับผิดชอบชดใช้เงินค่าใช้จ่ายส่วนเกินที่บริษัทประกันภัยไม่ได้คุ้มครอง
        </b>
      </>,
      'คำอธิบาย : ประกันความคุ้มครองและสิทธิประโยชน์',
      'ประกันพื้นฐาน มีค่าเสียหายส่วนแรก (CDW)',
      `Collision Damage Waiver :CDW
      กรณีเกิดอุบัติเหตุระหว่างเช่าโดยที่ผู้เช่ารถเป็นฝ่ายผิดหรือไม่มีคู่กรณี หรือรถมีรอยขีดข่วนจากการขับขี่ ผู้เช่าต้องรับผิดชอบค่าเสียหายที่เกิดขึ้นไม่เกินค่าเสียหายส่วนแรกที่ทางบริษัทรถเช่ากำหนด
      การคุ้มครองนี้ครอบคลุมเพียงตัวรถและไม่ครอบคลุมส่วนอื่นๆ ของรถ (เช่น กระจก ยาง ภายใน หลังคา หรือช่วงล่างของรถ) หรือค่าปรับหรือค่าใช้จ่ายต่างๆ ที่อาจเกิดขึ้นระหว่างการเช่า หรืออุปกรณ์ใดๆ ที่มีอยู่ในรถ (เช่น เก้าอี้เด็ก อุปกรณ์ GPS หรือทรัพย์สินส่วนบุคคลอื่นๆ เป็นต้น) ทั้งนี้ การคุ้มครองนี้มีผลก็ต่อเมื่อท่านปฏิบัติตามข้อตกลงของการเช่าเท่านั้น`,
      'ประกันภัยบุคคลที่ 3 (TPL)',
      `Third Party Liability :TPL
      ในกรณีที่เกิดความเสียหายทางร่างกายและทรัพย์สินของบุคคลที่ 3 อันเกิดจากความผิดของผู้ขับขี่ระหว่างการเช่านั้น ประกันภัยบุคคลที่ 3 (TPL) จะคุ้มครองถึงความรับผิดดังกล่าวต่อร่างกายและทรัพย์สินตามนโยบาย ทั้งนี้ ความคุ้มครองไม่รวมถึงความเสียหายใดทั้งทางร่างกายและทรัพย์สินของผู้เช่าเอง หรือต่อรถที่เช่า และการคุ้มครองนี้มีผลต่อเมื่อท่านปฏิบัติตามข้อตกลงการเช่าเท่านั้น`,
      'กรณีเลือกซื้อประกันเพิ่มความคุ้มครองของผู้เช่ารถ',
      'ประกันแบบไม่มีค่าเสียหายส่วนแรก Zero Excess (SCDW)',
      `Super Collision Damage Waiver :SCDW
      กรณีเกิดอุบัติเหตุระหว่างเช่า ผู้เช่าไม่ต้องรับผิดชอบค่าเสียหายที่เกิดขึ้น การคุ้มครองนี้ครอบคลุมเพียงตัวรถ และไม่ครอบคลุมส่วนอื่นๆ ของรถ (เช่น กระจก ยาง ภายใน หลังคา หรือช่วงล่างของรถ) หรือค่าปรับหรือค่าใช้จ่ายต่างๆ ที่อาจเกิดขึ้นระหว่างการเช่า หรืออุปกรณ์ใดๆ ที่มีอยู่ในรถ (เช่น เก้าอี้เด็ก อุปกรณ์ GPS หรือทรัพย์สินส่วนบุคคลอื่นๆ เป็นต้น) ทั้งนี้ การคุ้มครองนี้มีผลก็ต่อเมื่อท่านปฏิบัติตามข้อตกลงของการเช่าเท่านั้น`,
      'ประกันแบบไม่มีค่าเสียหายส่วนแรกเต็มรูปแบบ (PCDW)',
      `Premium Collision Damage Waiver :PCDW
      กรณีเกิดอุบัติเหตุระหว่างเช่า ผู้เช่าไม่ต้องรับผิดชอบค่าเสียหายที่เกิดขึ้น การคุ้มครองนี้ครอบคลุมเพียงตัวรถ กระจก ยาง และไม่ครอบคลุมส่วนอื่นๆ ของรถ (เช่น ภายใน หลังคา หรือช่วงล่างของรถ) หรือค่าปรับหรือค่าใช้จ่ายต่างๆ ที่อาจเกิดขึ้นระหว่างการเช่า หรืออุปกรณ์ใดๆ ที่มีอยู่ในรถ (เช่น เก้าอี้เด็ก อุปกรณ์ GPS หรือทรัพย์สินส่วนบุคคลอื่นๆ เป็นต้น) ทั้งนี้ การคุ้มครองนี้มีผลก็ต่อเมื่อท่านปฏิบัติตามข้อตกลงของการเช่าเท่านั้น`,
      'ประกันการโจรกรรม (TP)',
      `Theft Protection :TP
      หากรถยนต์ที่เช่าถูกขโมย ประกันภัยประเภทนี้จะครอบคลุมในส่วนที่ผู้เช่าจะต้องจ่ายชดเชยในส่วนที่สูญเสียไป ทั้งนี้ การคุ้มครองนี้มีผลก็ต่อเมื่อท่านปฏิบัติตามข้อตกลงของการเช่าเท่านั้น`,
    ],
    en: [
      'Vehicles listed for rental on Drivehub systems all includes a "Basic First-Class Insurance" as standard. This type of insurance however does not cover any excess charges incurred, in the event that the customer is invloved in an accident and is at fault or there are no litigants.',
      'In the event of an accident and you did not purchase any additional insurance coverage with the rental operator and the follwing is true: A) You are the party at fault, or B) There are no litigants. You are then obligated to cover any excess damage charges rated between 1,000-50,000 Baht depending on your vehicle types and classifications. The charges of each vehicle types are as follows:',
      <>
        If the insurance company appraises the price and concludes that{' '}
        <b>
          the damages exceed the coverage limits as specified, the
          lessee/borrower agrees to compensate for the excess amount not covered
          by the insurance company.
        </b>
      </>,
      'Explanation: Insurance Coverage and Benefits',
      'Basic Insurance: Collision Damage Waiver (CDW)',
      `Collision Damage Waiver: CDW
       refers to an accident during the rental period in which the lessee is the party at fault or there are no litigants, or the car is scratched from driving. The lessee shall be responsible for the damages incurred not exceeding the excess determined by the car rental company.
      This coverage covers only the body of the car, and does not cover other parts of the car (such as glass, tires, interior, roof or car suspension) or fines or expenses that may be incurred during the rental, or any equipment installed in the car (such as baby car seat, GPS device or other personal property, etc.). However, this coverage is effective only if you comply with the terms and conditions of rental agreement.`,
      'Third Party Liability (TPL)',
      `Third Party Liability: TPL
       refers to physical or property damage to a third party due to the fault of the driver during the rental period. It covers the liabilities for such physical and property damages in accordance with the policy. However, this coverage does not include any physical or property damage of the lessee or the rented car. In addition, this coverage is effective only if you comply with the terms of the rental agreement.`,
      'In the case that additional insurance coverage is purchased:',
      'Super Collision Damage Waiver (SCDW) with Zero Excess',
      `Super Collision Damage Waiver: SCDW
       refers to an accident during the rental period whereby the lessee will not be responsible for the damages incurred. This coverage covers only the body of the car, and does not cover other parts of the car (such as glass, tires, interior, roof or car suspension) or fines or expenses that may be incurred during the rental, or any equipment installed in the car (such as baby car seat, GPS device or other personal property, etc.). However, this coverage is effective only if you comply with the terms of rental agreement.`,
      'Premium Collison Damage Waiver (PCDW)',
      `Premium Collision Damage Waiver: PCDW
       refers to an accident during the rent period whereby the lessee will not be responsible for the damages incurred. This coverage covers only the body of the car, glass and tires, and does not cover other parts of the car (such as interior, roof or car suspension) or fines or expenses that may be incurred during the rental, or any equipment installed in the car (such as baby car seat, GPS device or other personal property, etc.). However, this coverage is effective only if you comply with the terms of the rental agreement.`,
      'Theft Protection (TP)',
      `Theft Protection: TP
       refers to when a rented car is stolen. This type of insurance covers only the portion of damages that the lessee is liable for. However, this coverage is effective only if you comply with the terms of the rental agreement.`,
    ],
  }

  const tableContent = {
    th: [
      'ประเภทรถ',
      'ตัวอย่าง',
      'ค่าเสียหายส่วนแรกสูงสุดไม่เกิน (บาท)',
      'รถอีโคร์คาร์ และ รถขนาดเล็ก',
      'March / Almera / Yaris / Vios / City / Jazz / Ciaz',
      '30,000',
      'รถขนาดกลาง',
      'Altis / Civic / CHR / HRV / Slyphy / Juke',
      '40,000',
      'รถขนาดใหญ่ และ รถเอสยูวี',
      'Camry / Accord / Fortuner / CRV / Pajero / Teana',
      '50,000',
      'ความคุ้มครอง / ประกัน',
      'CDW',
      'SCDW',
      'SCDW + TP',
      'PCDW + TP',
      'ประกันภัยบุคคลที่ 3',
      'ความคุ้มครองค่าเสียหายส่วนแรก (เฉพาะตัวถัง)',
      'รถถูกโจรกรรม',
      'กระจก / ยาง',
      'ราคาต่อวัน',
      'รวมในค่าเช่าแล้ว',
      '214-535 บาท',
      '268-589 บาท',
      '428-642 บาท',
    ],
    en: [
      'Type of Car',
      'Example',
      'Maximum Excess not Exceeding (Baht)',
      'Eco and Small Cars',
      'March / Almera / Yaris / Vios / City / Jazz / Ciaz',
      '30,000',
      'Medium Cars',
      'Altis / Civic / CHR / HRV / Slyphy / Juke',
      '40,000',
      'Large Cars and SUVs',
      'Camry / Accord / Fortuner / CRV / Pajero / Teana',
      '50,000',
      'ความคุ้มครอง / ประกัน',
      'CDW',
      'SCDW',
      'SCDW + TP',
      'PCDW + TP',
      'ประกันภัยบุคคลที่ 3',
      'ความคุ้มครองค่าเสียหายส่วนแรก (เฉพาะตัวถัง)',
      'รถถูกโจรกรรม',
      'กระจก / ยาง',
      'ราคาต่อวัน',
      'รวมในค่าเช่าแล้ว',
      '214-535 บาท',
      '268-589 บาท',
      '428-642 บาท',
    ],
  }

  const text = content[prefix]
  const tableText = tableContent[prefix]

  return (
    <div className="content-page">
      <Tab>
        <TabPane hash="cash" title={<TabTitle type="local" />}>
          <div>
            <p>{text[0]}</p>
            <p>{text[1]}</p>
            <InsuranceTable>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <th>{tableText[0]}</th>
                    <th className="text-center">{tableText[1]}</th>
                    <th className="text-center">{tableText[2]}</th>
                  </tr>
                  <tr>
                    <td>{tableText[3]}</td>
                    <td className="text-center">{tableText[4]}</td>
                    <td className="text-center">{tableText[5]}</td>
                  </tr>
                  <tr>
                    <td>{tableText[6]}</td>
                    <td className="text-center">{tableText[7]}</td>
                    <td className="text-center">{tableText[8]}</td>
                  </tr>
                  <tr>
                    <td>{tableText[9]}</td>
                    <td className="text-center">{tableText[10]}</td>
                    <td className="text-center">{tableText[11]}</td>
                  </tr>
                </tbody>
              </table>
            </InsuranceTable>
            <p>{text[2]}</p>
          </div>
        </TabPane>
        <TabPane hash="credit" title={<TabTitle type="inter" />}>
          <div>
            <InsuranceTables />
            <InsuranceBox>
              <h2>{text[3]}</h2>
              <h3>{text[4]}</h3>
              <p>{text[5]}</p>
              <h3>{text[6]}</h3>
              <p>{text[7]}</p>
              <h3 className="mb-2 font-italic">{text[8]}</h3>
              <h3>{text[9]}</h3>
              <p>{text[10]}</p>
              <h3>{text[11]}</h3>
              <p>{text[12]}</p>
              <h3>{text[13]}</h3>
              <p>{text[14]}</p>
            </InsuranceBox>
          </div>
        </TabPane>
      </Tab>
    </div>
  )
}

const InsuranceTable = styled.div`
  table {
    font-size: 14px;

    @media (max-width: 768px) {
      font-size: 11px;
    }
    th {
      padding: 10px 5px;
    }
    td {
      padding: 10px 5px;
    }
  }
  img {
    height: 24px;

    @media (max-width: 768px) {
      height: 13px;
    }
  }
`

const InsuranceBox = styled.div`
  background-color: #e6eef8;
  padding: 15px;
  border-radius: 2px;
  h2 {
    margin-bottom: 35px;
  }
  h3 {
    margin-bottom: 10px;
    font-size: 14px;
  }
  p {
    margin-bottom: 45px;
    font-size: 14px;
  }
`
