import React from 'react'

const question = {
  icon: '',
  question: 'กรณีรับและส่งรถนอกเวลาทำการมีค่าใช้จ่ายเพิ่มเติมหรือไม่?',
  answer: props => (
    <div style={{ padding: 15 }}>
      <b>
        <u>หากท่านทำการจองรถที่ไม่ต้องใช้บัตรเครดิต</u>
      </b>
      <p className="indent">
        การรับและส่งคืนรถนอกเวลาทำการ อาจมีค่าบริการเพิ่มเติมต่อเที่ยว โดยประมาณจะอยู่ที่ 200-300
        บาท ขึ้นอยู่กับนโยบายของแต่ละบริษัทผู้ให้บริการ (เวลาทำการของบริษัทรถเช่าส่วนใหญ่จะอยู่ที่
        08:00-20:00 น.)
      </p>
      <b>
        <u>หากท่านทำการจองรถที่ต้องใช้บัตรเครดิต</u>
      </b>
      <div className="indent">
        การรับและส่งคืนรถนอกเวลาทำการ อาจมีค่าบริการเพิ่มเติมต่อเที่ยว โดยเริ่มต้น 214-535 บาท
        ขึ้นอยู่กับนโยบายของแต่ละบริษัทผู้ให้บริการ
      </div>
      <div className="mt-1">
        <b>หมายเหตุ:</b>
        เคาร์เตอร์บริษัทรถเช่าแต่ละสนามบินมีเวลาทำการที่แตกต่างกัน
        ท่านสามารถสอบถามข้อมูลโดยติดต่อเจ้าหน้าที่ Drivehub ที่เบอร์ 02-038-5222 หรือ Line Id:
        @Drivehub
      </div>
    </div>
  ),
}

export default question
