import React from 'react'
import PropTypes from 'prop-types'
import { BadgeContainer } from '../badge'
import local from '../../../static/assets/badges/local.svg'
import inter from '../../../static/assets/badges/inter.svg'
import './style.scss'

const LOCAL = 'local'

const TabTitle = ({ type }) => {
  return (
    <div className="tab-title__wrapper">
      <div className="tab-title__badge">
        <BadgeContainer
          icon={type === LOCAL ? local : inter}
          text={type === LOCAL ? 'LOCAL' : 'INTER'}
          background={type === LOCAL ? '#FF8D41' : '#0053BA'}
          fontColor="#FFFFFF"
        />
      </div>
      {type === LOCAL ? (
        <div className="tab-title__content">
          บริษัทรถเช่าท้องถิ่น <br />
          <span>
            เช่ารถโดย <b>ไม่</b>ต้องใช้บัตรเครดิต
          </span>
        </div>
      ) : (
        <div className="tab-title__content">
          บริษัทรถเช่ารายใหญ่ <br />
          <span>เช่ารถโดย ใช้บัตรเครดิต</span>
        </div>
      )}
    </div>
  )
}

TabTitle.propTypes = {
  type: PropTypes.oneOf(['local', 'inter']),
}

export default TabTitle
