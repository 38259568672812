import React from 'react'
import moment from 'moment-timezone'
import queryString from 'query-string'
import { StaticQuery, graphql, Link } from 'gatsby'
import { WEB_SEARCH_LINK, WEB_LEGACY_LINK } from '../../../config'
import './style.scss'

const CityList = ({ prefix = 'th' }) => (
  <StaticQuery
    query={graphql`
      query {
        cities: allMarkdownRemark(
          filter: { fields: { collection: { eq: "city" } } }
        ) {
          edges {
            node {
              id
              frontmatter {
                path
                name
                position
                location_id
                activate
                no_city_page
              }
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <div className="city-list" id="city-list">
          {data.cities.edges
            .sort(
              (a, b) =>
                Number(a.node.frontmatter.position) -
                Number(b.node.frontmatter.position),
            )
            .filter(({ node }) => node.frontmatter.activate)
            .map(({ node }, index) => {
              if (!node.frontmatter.no_city_page) {
                return (
                  <div key={index}>
                    <a
                      href={`${WEB_LEGACY_LINK}/${prefix}/${node.frontmatter.path}`}
                    >
                      เช่ารถ{node.frontmatter.name}
                    </a>
                  </div>
                )
              } else {
                const pickupDatetime = moment()
                  .add(1, 'days')
                  .format('YYYY-MM-DD 10:00')
                const reuturnDatetime = moment()
                  .add(2, 'days')
                  .format('YYYY-MM-DD 10:00')

                const params = {
                  location_id: node.frontmatter.location_id,
                  booking_begin: pickupDatetime,
                  booking_end: reuturnDatetime,
                  limit: 5,
                  offset: 0,
                  orderby: 'price',
                }
                const parsedParams = queryString.stringify(params)
                const url = `${WEB_SEARCH_LINK}/?${parsedParams}`
                return (
                  <div key={index}>
                    <a href={url}>เช่ารถ{node.frontmatter.name}</a>
                  </div>
                )
              }
            })}
        </div>
      )
    }}
  />
)

export default CityList
