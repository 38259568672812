import React from 'react'
import InsuranceTable from '../../insurance-table'
import styled from 'styled-components'
const question = {
  icon: '',
  question: 'ราคานี้มีประกันภัยรวมอยู่ด้วยรึเปล่า?',
  answer: props => (
    <div style={{ padding: 15 }}>
      <h3>
        <u>หากท่านทำการจองรถที่ไม่ต้องใช้บัตรเครดิต</u>
      </h3>
      <p className="indent">
        รถทุกคันในระบบของ Drivehub มีประกันภัยชั้น 1 ขั้นพื้นฐาน (ซึ่งรวมอยู่ในค่าเช่าแล้ว)
        แต่จะไม่รวมค่าเสียหายส่วนแรกในกรณีที่ลูกค้าเกิดอุบัติเหตุโดยท่านเป็นฝ่ายผิดหรือไม่มีคู่กรณี
      </p>
      <div>
        หากเกิดอุบัติเหตุและท่านไม่ซื้อประกันเพิ่มเติมกับบริษัทรถเช่า
        โดยท่านเป็นฝ่ายผิดหรือไม่มีคู่กรณีบริษัทรถเช่าจะเรียกเก็บค่าเสียหาย(“ค่าเสียหายส่วนแรก”)
        ระหว่าง1,000 ถึง 50,000บาท ในอัตราอ้างอิงตามประเภทรถดังนี้
        <table className="table text-center">
          <thead>
            <tr>
              <th>ประเภทรถ</th>
              <th>ตัวอย่าง</th>
              <th>ค่าเสียหายส่วนแรกมากที่สุด (บาท)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>รถอีโคคาร์ และ รถขนาดเล็ก</td>
              <td>March / Almera / Yaris / Vios / City / Jazz / Ciaz</td>
              <td>30,000</td>
            </tr>
            <tr>
              <td>รถขนาดกลาง</td>
              <td>Altis / Civic / CHR / HRV / Slyphy / Juke</td>
              <td>40,000</td>
            </tr>
            <tr>
              <td>รถขนาดใหญ่ และ รถเอสยูวี</td>
              <td>Camry / Accord / Fortuner / CRV / Pajero / Teana</td>
              <td>50,000</td>
            </tr>
          </tbody>
        </table>
        <p>
          หากบริษัทประกันภัยประเมินราคาแล้ว ได้ความว่า ค่าเสียหายเกินกว่าวงเงินคุ้มครองที่กำหนดไว้
          ผู้เช่า/ผู้ยืมตกลงรับผิดชอบชดใช้เงินค่าใช้จ่ายส่วนเกินที่บริษัทประกันภัยไม่ได้คุ้มครอง
        </p>
      </div>
      <h3>
        <u>หากท่านทำการจองรถที่ต้องใช้บัตรเครดิต</u>
      </h3>
      <InsuranceTable />
      <InsuranceBox>
        <h2>คำอธิบาย : ประกันความคุ้มครองและสิทธิประโยชน์</h2>
        <h3>ประกันพื้นฐาน มีค่าเสียหายส่วนแรก (CDW)</h3>
        <p>
          Collision Damage Waiver :CDW
          กรณีเกิดอุบัติเหตุระหว่างเช่าโดยที่ผู้เช่ารถเป็นฝ่ายผิดหรือไม่มีคู่กรณี
          หรือรถมีรอยขีดข่วนจากการขับขี่
          ผู้เช่าต้องรับผิดชอบค่าเสียหายที่เกิดขึ้นไม่เกินค่าเสียหายส่วนแรกที่ทางบริษัทรถเช่ากำหนด
          การคุ้มครองนี้ครอบคลุมเพียงตัวรถและไม่ครอบคลุมส่วนอื่นๆ ของรถ (เช่น กระจก ยาง ภายใน หลังคา
          หรือช่วงล่างของรถ) หรือค่าปรับหรือค่าใช้จ่ายต่างๆ ที่อาจเกิดขึ้นระหว่างการเช่า
          หรืออุปกรณ์ใดๆ ที่มีอยู่ในรถ (เช่น เก้าอี้เด็ก อุปกรณ์ GPS หรือทรัพย์สินส่วนบุคคลอื่นๆ
          เป็นต้น) ทั้งนี้ การคุ้มครองนี้มีผลก็ต่อเมื่อท่านปฏิบัติตามข้อตกลงของการเช่าเท่านั้น`
        </p>
        <h3>ประกันภัยบุคคลที่ 3 (TPL)</h3>
        <p>
          Third Party Liability :TPL ในกรณีที่เกิดความเสียหายทางร่างกายและทรัพย์สินของบุคคลที่ 3
          อันเกิดจากความผิดของผู้ขับขี่ระหว่างการเช่านั้น ประกันภัยบุคคลที่ 3 (TPL)
          จะคุ้มครองถึงความรับผิดดังกล่าวต่อร่างกายและทรัพย์สินตามนโยบาย ทั้งนี้
          ความคุ้มครองไม่รวมถึงความเสียหายใดทั้งทางร่างกายและทรัพย์สินของผู้เช่าเอง หรือต่อรถที่เช่า
          และการคุ้มครองนี้มีผลต่อเมื่อท่านปฏิบัติตามข้อตกลงการเช่าเท่านั้น`,
          'กรณีเลือกซื้อประกันเพิ่มความคุ้มครองของผู้เช่ารถ
        </p>
        <h3 className="mb-2 font-italic">กรณีเลือกซื้อประกันเพิ่มความคุ้มครองของผู้เช่ารถ</h3>
        <h3>ประกันแบบไม่มีค่าเสียหายส่วนแรก Zero Excess (SCDW)</h3>
        <p>
          Super Collision Damage Waiver :SCDW กรณีเกิดอุบัติเหตุระหว่างเช่า
          ผู้เช่าไม่ต้องรับผิดชอบค่าเสียหายที่เกิดขึ้น การคุ้มครองนี้ครอบคลุมเพียงตัวรถ
          และไม่ครอบคลุมส่วนอื่นๆ ของรถ (เช่น กระจก ยาง ภายใน หลังคา หรือช่วงล่างของรถ)
          หรือค่าปรับหรือค่าใช้จ่ายต่างๆ ที่อาจเกิดขึ้นระหว่างการเช่า หรืออุปกรณ์ใดๆ ที่มีอยู่ในรถ
          (เช่น เก้าอี้เด็ก อุปกรณ์ GPS หรือทรัพย์สินส่วนบุคคลอื่นๆ เป็นต้น) ทั้งนี้
          การคุ้มครองนี้มีผลก็ต่อเมื่อท่านปฏิบัติตามข้อตกลงของการเช่าเท่านั้น
        </p>
        <h3>ประกันแบบไม่มีค่าเสียหายส่วนแรกเต็มรูปแบบ (PCDW)</h3>
        <p>
          Premium Collision Damage Waiver :PCDW กรณีเกิดอุบัติเหตุระหว่างเช่า
          ผู้เช่าไม่ต้องรับผิดชอบค่าเสียหายที่เกิดขึ้น การคุ้มครองนี้ครอบคลุมเพียงตัวรถ กระจก ยาง
          และไม่ครอบคลุมส่วนอื่นๆ ของรถ (เช่น ภายใน หลังคา หรือช่วงล่างของรถ)
          หรือค่าปรับหรือค่าใช้จ่ายต่างๆ ที่อาจเกิดขึ้นระหว่างการเช่า หรืออุปกรณ์ใดๆ ที่มีอยู่ในรถ
          (เช่น เก้าอี้เด็ก อุปกรณ์ GPS หรือทรัพย์สินส่วนบุคคลอื่นๆ เป็นต้น) ทั้งนี้
          การคุ้มครองนี้มีผลก็ต่อเมื่อท่านปฏิบัติตามข้อตกลงของการเช่าเท่านั้น
        </p>
        <h3>ประกันการโจรกรรม (TP)</h3>
        <p>
          Theft Protection :TP หากรถยนต์ที่เช่าถูกขโมย
          ประกันภัยประเภทนี้จะครอบคลุมในส่วนที่ผู้เช่าจะต้องจ่ายชดเชยในส่วนที่สูญเสียไป ทั้งนี้
          การคุ้มครองนี้มีผลก็ต่อเมื่อท่านปฏิบัติตามข้อตกลงของการเช่าเท่านั้น
        </p>
      </InsuranceBox>
    </div>
  ),
}

const InsuranceBox = styled.div`
  background-color: #e6eef8;
  padding: 15px;
  border-radius: 2px;
  h2 {
    margin-bottom: 35px;
    color: #000;
    text-align: left;
  }
  h3 {
    margin-bottom: 10px;
    font-size: 14px;
  }
  p {
    margin-bottom: 45px;
    font-size: 14px;
  }
`

export default question
