import React from 'react'
import Tab, { TabPane } from '../../tab'
import CreditSymbol from '../../../../static/assets/content/credit_symbol.png'
import KPNLogo from '../../../../static/assets/content/kpn_logo.png'
import NationalLogo from '../../../../static/assets/content/national_logo.png'
import SixtLogo from '../../../../static/assets/content/sixt_logo.png'

const question = {
  icon: 'flaticon-book',
  question: 'จะต้องทำการชำระเงินอย่างไร?',
  answer: ({ prefix }) => (
    <div style={{ padding: 15 }}>
      <Tab>
        <TabPane
          title={
            <span>
              ชำระด้วยเงินสด หรือ <br /> บัตรเครดิต ณ วันรับรถ
            </span>
          }
        >
          <div>
            <p>
              สำหรับลูกค้าใหม่หรือทำการจองรถช่วงเทศกาล
              <b>
                ทางบริษัทรถเช่าอาจเรียกเก็บค่าเช่าล่วงหน้าเพื่อล็อคคิวรถเป็นจำนวนเงิน 500-1000 บาท
              </b>
              โดยจะหักจากค่าเช่ารถ มิได้เป็นการเรียกเก็บเพิ่มเติม
              และในวันรับรถท่านจะชำระเพียงค่าเช่าส่วนที่เหลือพร้อมกับค่าประกันความเสียหายด้วยบัตรเครดิตหรือเงินสด
            </p>
            <p>
              หากชำระด้วยบัตรเครดิต จะมีการเรียกเก็บค่าธรรมเนียมเพิ่มเติมประมาณ 3%
              จากราคาเช่าและค่าประกันความเสียหาย
              <b>
                ซึ่งจะมีให้บริการในเพียงบางจังหวัด
                และบริษัทรถเช่าเพียงบางร้านเท่านั้นที่สามารถรับชำระด้วยบัตรเครดิตได้
              </b>
            </p>
            <p>
              *กรณีเช่ารถในช่วงวันเทศกาลหรือไฮซีซั่น (เดือนตุลาคมถึงกุมภาพันธ์)
              ทางร้านอาจขอเรียกเก็บค่าล็อคคิวรถในจำนวนที่เพิ่มขึ้นทั้งนี้ขึ้นอยู่กับเงื่อนไขของทางบริษัทรถเช่านั้นๆ
            </p>
          </div>
        </TabPane>
        <TabPane title="ชำระด้วยบัตรเครดิตบนหน้าเว็บ*">
          <React.Fragment>
            <div>
              <p>สามารถใช้บัตรเครดิตชำระค่าเช่าล่วงหน้าผ่านเว็บไซต์ได้ทันที</p>
              <div className="faq_note">
                <u>หมายเหตุ</u>
                <br />
                *เฉพาะรถที่มีสัญลักษณ์บัตรเครดิตเท่านั้น{' '}
                <img src={CreditSymbol} height="47" alt="Credit Card" />
              </div>
            </div>
            <p className="mt-2" style={{ textDecoration: 'underline' }}>
              บริษัทรถเช่าที่เปิดให้บริการ
            </p>
            <div className="faq_bigbrand_logo mb-2">
              <img src={KPNLogo} height="30" alt="KPN" />
              <img src={NationalLogo} height="30" alt="National" />
              <img src={SixtLogo} height="30" alt="Sixt" />
            </div>
          </React.Fragment>
        </TabPane>
      </Tab>
    </div>
  ),
}

export default question
