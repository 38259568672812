import React from 'react'

const question = {
  icon: '',
  question: 'ต้องทำการจองรถอย่างไร?',
  answer: props => (
    <div style={{ padding: 15 }}>
      <div className="indent">
        หลังจากที่ท่านเลือกสถานที่รับรถ วันและเวลา ที่ต้องการรับและส่งคืนรถ
        เมื่อเลือกรถคันที่ต้องการได้เรียบร้อยแล้ว
        กรุณาตรวจสอบรายละเอียดราคาค่าเช่า
        และค่าประกันความเสียหายของรถคันนั้นๆให้ครบถ้วนก่อนทำการจอง
        โดยท่านสามารถดำเนินการจองได้ 3 ช่องทาง ดังต่อไปนี้
      </div>
      <ol>
        <li>
          กดจองออนไลน์ผ่านหน้าเว็บไซต์ เพียงกรอกชื่อ-นามสกุล อีเมล
          และเบอร์โทรศัพท์
        </li>
        <li>จองผ่าน Call Center Drivehub ที่เบอร์ 02-038-5222</li>
        <li>จองผ่าน Line Official : @Drivehub</li>
      </ol>
      <p>
        <u>ต้องทำอย่างไร? หลังจากทำการจองเสร็จแล้ว</u>
      </p>
      <b>
        <u>หากท่านทำการจองรถที่ไม่ต้องใช้บัตรเครดิต</u>
      </b>
      <div className="indent">
        โดยหลังจากที่ทำการจองเสร็จสิ้น ท่านจะได้รับหมายเลขการจอง
        และจะมีเจ้าหน้าที่จากบริษัทรถเช่าติดต่อกลับ
        เพื่อยืนยันเอกสารและยืนยันการจองอีกครั้ง โปรดเตรียม
        {/* eslint-disable-next-line */}
        <a
          target="_blank"
          onClick={() => props.toggle(1)}
          style={{ color: '#000', cursor: 'pointer' }}
        >
          <u>เอกสารประกอบการเช่า</u>
        </a>
        และ
        {/* eslint-disable-next-line */}
        <a
          style={{ color: '#000', cursor: 'pointer' }}
          onClick={() => props.toggle(6)}
        >
          <u>ค่าล็อคคิวรถ</u>
        </a>
        ท่านสามารถส่งข้อมูลโดยตรงให้กับเจ้าหน้าที่บริษัทรถเช่า
      </div>
      <b>
        <u>หากท่านทำการจองรถที่ต้องใช้บัตรเครดิต</u>
      </b>
      <div className="indent">
        โดยหลังจากที่ทำการจองเสร็จสิ้น ท่านจะได้รับหมายเลขการจอง
        เพื่อเป็นการยืนยันการจองทันที โปรดเตรียม
        {/* eslint-disable-next-line */}
        <a
          target="_blank"
          onClick={() => props.toggle(1)}
          style={{ color: '#000', cursor: 'pointer' }}
        >
          <u>เอกสารประกอบการเช่า</u>
        </a>
        ให้ครบถ้วนในวันรับรถ
      </div>
    </div>
  ),
}

export default question

// 7 LOCK Q

// 2 DOC
