import React from 'react'
import './style.scss'
import ListYourCar from '../components/list-your-car'

export default prefix => {
  return (
    <div className="content-page">
      <ListYourCar prefix={prefix} content={content} />
    </div>
  )
}

const content = {
  description: {
    th: [
      'หากคุณเป็นผู้ประกอบการรถเช่าที่มีมาตรฐานและมีความประสงค์ในการใช้ซอฟต์แวร์ระบบการจัดการรถเช่าและต้องการโฆษณารถเช่าที่มีอยู่บนแพลตฟอร์มของเรา กรุณากรอกข้อมูลเกี่ยวกับธุรกิจรถเช่าของคุณและทีมงานพัฒนาธุรกิจของเราจะทำการติดต่อกลับภายใน 3-5 วัน ในกรณีที่บริษัท/ร้านของท่าน มีรถเช่ามากกว่า 3 คันขึ้นไป',
    ],
    en: [
      'If you operate an approved car rental business and wishes to list your vehicles on our car rental management system and advertise available cars for rent on our website platform, please fill in the form with full business information of your company. Our business development team will be in contact with you within 24 hours.',
    ],
  },
  form: {
    th: {
      header: ['รายละเอียดธุรกิจของคุณ', 'ข้อมูลสำหรับการติดต่อกลับ'],
      input: [
        {
          name: 'จังหวัด',
          placeholder: 'กรุณาเลือกจังหวัด',
        },
        {
          name: 'ชื่อร้าน/บริษัท',
          placeholder: 'กรุณากรอกชื่อร้าน/บริษัท',
        },
        {
          name: 'ที่อยู่',
          placeholder: 'กรุณากรอกที่อยู่ของร้าน/บริษัท',
        },
        {
          name: 'จำนวนรถ',
          placeholder: 'กรุณาระบุจำนวนรถ',
        },
        {
          name: 'ชื่อผู้ติดต่อ',
          placeholder: 'กรุณากรอกชื่อผู้ติดต่อ',
        },
        {
          name: 'เบอร์ติดต่อ',
          placeholder: 'กรุณากรอกเบอร์ติดต่อ',
        },
        {
          name: 'อีเมล',
          placeholder: 'กรุณากรอกอีเมล',
        },
      ],
    },
    en: {
      header: ['Business Information', 'Contact Information'],
      input: [
        {
          name: 'Location:',
          placeholder: 'Select Province',
        },
        {
          name: 'Name of Store/Company:',
          placeholder: 'Enter Here',
        },
        {
          name: 'Address:',
          placeholder: 'Enter Here',
        },
        {
          name: 'Number of Cars:',
          placeholder: 'Enter Here',
        },
        {
          name: 'Name:',
          placeholder: 'Enter Here',
        },
        {
          name: 'Telephone Number:',
          placeholder: 'Enter Here',
        },
        {
          name: 'Email:',
          placeholder: 'Enter Here',
        },
      ],
    },
  },
  button: {
    th: 'ร่วมเป็นพันธมิตรกับเรา',
    en: 'ร่วมเป็นพันธมิตรกับเรา',
  },
}
